
export default function addcsvquotes (str) {
	if(str == null){
		return 'No Comment';
	}else{
		if (str.substring(str.length - 1) == '"') {
			str = str + ' '
		}
		return (str + '').toString().replace('"', '""')
	}
}
