import ItemSort from "./itemSort";
import SktDb from "./sktDb";
import getCookie from "./getCookie";
import findGetParameter from "./findGetParameter";
import addcsvquotes from "./addcsvquotes";
import autocomplete from "./autocomplete";
import displayNotification from "./displayNotification";
import { uniqBy } from "lodash";

// globals
import { LOW_ALERT_QUANTITY_MOD } from "./globals";
import syncDown from "./syncDown";

export default async function prepareArchiveReportPage() {

  // SktDb.remove("room-inventory", 0);
  
	$('.barcodes_action').on('change', function(e){
		window.barcodesArray = Array();
		
		$('input:checkbox.barcodes_action').each(function () {
			var currentCheckboxVal = (this.checked ? $(this).val() : "");
			
			if(currentCheckboxVal != ''){
				window.barcodesArray.push(currentCheckboxVal);
			}
		});
		
		if(window.barcodesArray.length > 0){
			$('#barcode-count').html(window.barcodesArray.length);
			
			if(!$('#barcode-system-submit').hasClass('active')){
				$('#barcode-system-submit').addClass('active');
			}
		}else{
			$('#barcode-system-submit').removeClass('active');
		}
		
		console.log(window.barcodesArray);
	});

	function resetSearchBar(input) {
		/*autocomplete(document.getElementById("product-name"), input);*/
		/*autocomplete(document.getElementById("barcode-input-form-field"), input);*/
		autocomplete(document.getElementById("desktop-autocomplete-search"), input);
		autocomplete(document.getElementById("mobile-autocomplete-search"), input);
	}

	// Setup buttons. Process it.
	$("#barcode-system-submit button").click(async (e) => {
		e.preventDefault();
   
		let data = new FormData();
		let main_data = window.barcodesArray.join(',');
		let action_type =  event.target.getAttribute("data-action");;
		let target = '';
		data.append("barcode_ids", main_data);
		
		if(action_type == 'active'){
			target = '/onlinerequest/enable-barcode';
		}else{
			target = '/onlinerequest/archive-barcode';
		}
		
		console.log(target);
		
		let response = await fetch(target, {
			method: 'POST',
			body: data
		});
		
		response = await response.json();
		console.log(response);
		
		if(response.error){
			console.log(response.message);
			const notification = {
				status: "failed",
				message: response.message
			}
			displayNotification(notification);
			
		}else{
			
			if(typeof(response.ri_ids) != 'undefined'){
				if(response.ri_ids.length > 0){
					for (let i = 0; i < response.ri_ids.length; i++) {
						SktDb.remove("room-inventory", response.ri_ids[i]);
					}
				}
			}
			
			window.location.href = "/manage/items";
		}
    }); 

	function filterSKUs() {
		// Declare variables
		var input, i, filter;
		input = $("#archived-search");
		filter = input.val().toUpperCase();
		let tbody = $("tbody");
		let children = tbody.children();

		for (i = 0; i < children.length; i++) {
			let row = children[i];
			let barcode = row.childNodes[1].innerText;
			let product_name = row.childNodes[3].innerText;
			let activeOr = row.childNodes[5].innerText;

			if (
				barcode.toUpperCase().indexOf(filter) > -1 ||
				product_name.toUpperCase().indexOf(filter) > -1 ||
				activeOr.toUpperCase().indexOf(filter) > -1
			) {
				row.style.display = "";
			} else {
				row.style.display = "none";
			}
		}
	}

	$("#archived-search").on("input", () => {
		filterSKUs();
	});
}
