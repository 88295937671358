import setLongLastingCookie from './setLongLastingCookie'
import prepareItemsIndexPage from './prepareItemsIndexPage'
// globals

export default async function domAssignmentFunctions () {
  $('body').on('click', '.delete-item', async (e) => {
    const roomInventoryId = $(e.currentTarget).data('id')
    if (confirm('Are you sure you want to delete this Item?')) {
      await fetch('/api/delete-room-inventory/' + roomInventoryId, {
        method: 'DELETE'
      })
      const roomInventory = await SktDb.getRecord('room-inventory', roomInventoryId)
      roomInventory.deleted = '1'
      await SktDb.add(roomInventory, 'room-inventory')
      document.location = '/items'
    }
  })

  $('.filter-submit').on('click', async () => {
    console.log('clicked')
    const $sortSetting = $('#sortby-container input[type=radio]:checked').val()
    const $filterSetting = $('#filter-container input[type=radio]:checked').val()
    const $sortDirection = $('#direction-container input[type=radio]:checked').val()
    const $roomFilter = $('#filter-container #room-select').val()

    const item_filters = {
      sort: $sortSetting,
      sortdirection: $sortDirection,
      filter: $filterSetting,
      roomFilter: $roomFilter
    }

    console.log(item_filters)

    // set cookie here
    setLongLastingCookie('persistent_item_filter', item_filters)

    prepareItemsIndexPage(item_filters)
  })
   
}
