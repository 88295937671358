
export default async function handleBugReporting() {
  $('.bug-submit').on('click', async (e) => {
      let item = {
          title: $("#bug-title").val(),
          description: $("#bug-description").val(),
          email: $("#bug-email").val()

      }

      var form_data = new FormData();


      for ( var key in item ) {
        form_data.append(key, item[key]);
    }
    
    $.ajax({
        url         : '/api/bug-reporting',
        data        : form_data,
        processData : false,
        contentType : false,
        type: 'POST'
    }).done(function(data){
        // do stuff
        // console.log(data)
        try {
            let json = JSON.parse(data)
            console.log(JSON.parse(json.message).id == true)
            console.log('pass')

            // Wipe Form Clean
            $("#bug-title").val(''),
            $("#bug-description").val(''),
            $("#bug-email").val('')
            
        } catch (error) {
            console.error(error)
        }
        

        
    })


    //   console.log(obj)

    //   let res = await fetch('/api/bug-reporting', {
    //       method:"POST",
    //       body: {test: 'test'}
    //   })

    //   res = await res.json()
    //   console.log(res)
  })  
}