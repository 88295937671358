import iOS from './iOS'

// globals
import './globals'

export default function readBarcode () {
  const resultCollector = Quagga.ResultCollector.create({
    capture: true,
    capacity: 20,
    blacklist: [{
      code: 'WIWV8ETQZ1',
      format: 'code_93'
    }, {
      code: 'EH3C-%GU23RK3',
      format: 'code_93'
    }, {
      code: 'O308SIHQOXN5SA/PJ',
      format: 'code_93'
    }, {
      code: 'DG7Q$TV8JQ/EN',
      format: 'code_93'
    }, {
      code: 'VOFD1DB5A.1F6QU',
      format: 'code_93'
    }, {
      code: '4SO64P4X8 U4YUU1T-',
      format: 'code_93'
    }],
    filter: function (codeResult) {
      // only store results which match this constraint
      // e.g.: codeResult
      return true
    }
  })
  const App = {
    init: function () {
      const self = this

      Quagga.init(this.state, function (err) {
        if (err) {
          return self.handleError(err)
        }
        // Quagga.registerResultCollector(resultCollector);
        App.attachListeners()
        App.checkCapabilities()
        Quagga.start()
      })
    },
    handleError: function (err) {
      console.log(err)
    },
    checkCapabilities: function () {
      const track = Quagga.CameraAccess.getActiveTrack()
      let capabilities = {}
      if (typeof track.getCapabilities === 'function') {
        capabilities = track.getCapabilities()
      }
      this.applySettingsVisibility('zoom', capabilities.zoom)
      this.applySettingsVisibility('torch', capabilities.torch)
    },
    applySettingsVisibility: function (setting, capability) {
      let node = false
      // depending on type of capability
      if (typeof capability === 'boolean') {
        node = document.querySelector('input[name="settings_' + setting + '"]')
        if (node) {
          node.parentNode.style.display = capability ? 'block' : 'none'
        }
        return
      }
      if (window.MediaSettingsRange && capability instanceof window.MediaSettingsRange) {
        node = document.querySelector('select[name="settings_' + setting + '"]')
        if (node) {
          node.parentNode.style.display = 'block'
        }
      }
    },
    attachListeners: function () {
      const self = this

      $('.controls .reader-config-group').on('change', 'input, select', function (e) {
        e.preventDefault()
        const $target = $(e.target)
        const value = $target.attr('type') === 'checkbox' ? $target.prop('checked') : $target.val()
        const name = $target.attr('name')
        const state = self._convertNameToState(name)

        console.log('Value of ' + state + ' changed to ' + value)
        self.setState(state, value)
      })
    },
    _printCollectedResults: function () {
      const results = resultCollector.getResults()
      const $ul = $('#result_strip ul.collector')

      results.forEach(function (result) {
        const $li = $('<li><div class="thumbnail"><div class="imgWrapper"><img /></div><div class="caption"><h4 class="code"></h4></div></div></li>')
        $li.find('img').attr('src', result.frame)
        $li.find('h4.code').html(result.codeResult.code + ' (' + result.codeResult.format + ')')
        $ul.prepend($li)
      })
    },
    _accessByPath: function (obj, path, val) {
      const parts = path.split('.')
      const depth = parts.length
      const setter = (typeof val !== 'undefined')

      return parts.reduce(function (o, key, i) {
        if (setter && (i + 1) === depth) {
          if (typeof o[key] === 'object' && typeof val === 'object') {
            Object.assign(o[key], val)
          } else {
            o[key] = val
          }
        }
        return key in o ? o[key] : {}
      }, obj)
    },
    _convertNameToState: function (name) {
      return name.replace('_', '.').split('-').reduce(function (result, value) {
        return result + value.charAt(0).toUpperCase() + value.substring(1)
      })
    },
    detachListeners: function () {
      $('.controls .reader-config-group').off('change', 'input, select')
    },
    applySetting: function (setting, value) {
      const track = Quagga.CameraAccess.getActiveTrack()
      if (track && typeof track.getCapabilities === 'function') {
        switch (setting) {
          case 'zoom':
            return track.applyConstraints({
              advanced: [{
                zoom: parseFloat(value)
              }]
            })
          case 'torch':
            return track.applyConstraints({
              advanced: [{
                torch: !!value
              }]
            })
        }
      }
    },
    setState: function (path, value) {
      const self = this

      if (typeof self._accessByPath(self.inputMapper, path) === 'function') {
        value = self._accessByPath(self.inputMapper, path)(value)
      }

      if (path.startsWith('settings.')) {
        const setting = path.substring(9)
        return self.applySetting(setting, value)
      }
      self._accessByPath(self.state, path, value)

      console.log(JSON.stringify(self.state))
      App.detachListeners()
      Quagga.stop()
      App.init()
    },
    inputMapper: {
      inputStream: {
        constraints: function (value) {
          if (/^(\d+)x(\d+)$/.test(value)) {
            const values = value.split('x')
            return {
              width: {
                min: parseInt(values[0])
              },
              height: {
                min: parseInt(values[1])
              }
            }
          }
          return {
            deviceId: value
          }
        }
      },
      numOfWorkers: function (value) {
        return parseInt(value)
      },
      decoder: {
        readers: [
          'upc_reader'
        ]
      }
    },
    state: {
      inputStream: {
        type: 'LiveStream',
        constraints: {
          width: {
            min: iOS() ? 640 : 320
          },
          height: {
            min: iOS() ? 480 : 240
          },
          facingMode: 'environment',
          aspectRatio: {
            min: 1,
            max: 2
          }
        },
        area: {
          top: '25%',
          right: '10%',
          left: '10%',
          bottom: '40%'
        }
      },
      locator: false,
      numOfWorkers: 0,
      frequency: 60,
      decoder: {
        readers: [
          'code_128_reader',
          'upc_reader',
          'upc_e_reader',
          'ean_reader',
          'ean_8_reader',
          'code_39_reader',
          'code_39_vin_reader',
          'codabar_reader',
          'i2of5_reader',
          '2of5_reader',
          'code_93_reader',
          'code_32_reader'
        ],
        multiple: false
      },
      locate: true
    },
    lastResult: null
  }

  App.init()

  Quagga.onProcessed(function (result) {
    const drawingCtx = Quagga.canvas.ctx.overlay
    const drawingCanvas = Quagga.canvas.dom.overlay
    if (result) {
      if (result.boxes) {
        drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute('width')), parseInt(drawingCanvas.getAttribute('height')))
        result.boxes.filter(function (box) {
          return box !== result.box
        }).forEach(function (box) {
          Quagga.ImageDebug.drawPath(box, {
            x: 0,
            y: 1
          }, drawingCtx, {
            color: 'green',
            lineWidth: 2
          })
        })
      }

      if (result.box) {
        Quagga.ImageDebug.drawPath(result.box, {
          x: 0,
          y: 1
        }, drawingCtx, {
          color: '#00F',
          lineWidth: 2
        })
      }

      if (result.codeResult && result.codeResult.code) {
        Quagga.ImageDebug.drawPath(result.line, {
          x: 'x',
          y: 'y'
        }, drawingCtx, {
          color: 'red',
          lineWidth: 3
        })
      }
    }
  })

  Quagga.onDetected(function (result) {
    results.push({
      code: result.codeResult.code,
      format: result.codeResult.format
    })
    results2.push(result.codeResult.code, result.codeResult.format)
    if (results.length >= 40) {
      if (document.getElementById('barcode-scanned').value === '') {
        const code = results2.getPrimaryResult()
        document.getElementById('barcode-scanned').value = code
        document.getElementById('barcode-scanned-type').value = results2.getPrimaryResultFormat()
        document.getElementById('spinner').style.display = 'none'
      }
    }
  })
}
;
