import setUpEditQuantity from './setUpEditQuantity'
import SktDb from './sktDb'
import getCookie from './getCookie'
// globals
import { urlParams } from './globals'
import displayNotification from './displayNotification';
import smartLookUp from './smartLookUp';

export default async function prepareAddItemPage () {
  // Next two lines only matter if creating inventory from base item found in items index
  const barcode_id = urlParams.get('id')
  smartLookUp();
  
  let backButton = '/'+sessionStorage.getItem('itemBackButton');
  $('#back-button').attr('href', backButton);
  
  if (barcode_id !== null) populateBarcodeInfo(barcode_id)

  setUpEditQuantity()
  const Location = ({
    id,
    name,
	live
  }) => {
		console.log(live);
		if(live == 1){
			return `<option value="${id}">${name}</option>`;
		}
	}
  const Room = ({
    id,
    name
  }) => {
		
		return `<option value="${id}">${name}</option>`;
		
	}

  let rooms = await SktDb.readAll('room')
  rooms = rooms.filter(x => x.deleted == 0)
  
  const locations = await SktDb.readAll('location')
  $('.input-wrapper #locations').append(locations.map(Location).join(''))

  const setUpItemLocationSelect = (e) => {
    const locationId = $('.input-wrapper #locations').val()
    const locationsRooms = rooms.filter(room => room.location_id === locationId)
    $('.input-wrapper #rooms').html('')
    $('.input-wrapper #rooms').append(locationsRooms.map(Room).join(''))
  }
  setUpItemLocationSelect()
  $('.input-wrapper #locations').on('change', setUpItemLocationSelect)

  // Form submission through JS	
  $('#add-item-container form').on('submit', async (e) => {
    e.preventDefault()
	let redirectTo = $('#add-item-form').data('savepath');
	
	let barcodeField = $('#barcode-input-form-field').val();
	let nameField = $('#product-name').val();
	let uomField = $('#uom_primary_unit_type').val();
	if (barcodeField == '' && nameField == ''){
		return false;
	}
	if (barcodeField != '' && nameField == ''){
		$('#product-name').focus();
		/*$('#smartLookUp').hide();*/
		return false;
	}
	if(uomField == 'N/A'){
		const uomError = {
			status: 'failed',
			message: 'Unit of Measure is Required'
		}
		displayNotification(uomError);
		return false;
	}
	
	
    $('#main-loading-spinner').fadeIn()
	
	//prepare our form data
    const formElement = $('form')[0]
    const form_data = new FormData(formElement)
	
	//ping the API
    const response = await fetch('/api/add-item', {
      method: 'POST',
      body: form_data
    })
	
    const data = await response.json()
	
	
	if (data.message) {
		if(typeof(data.image_src) != 'undefined'){
			form_data.append('image_src', data.image_src);
		}
		const notification = {
			status: data.status,
			message: data.message
		}
		if (data.status === 'success') {
			sessionStorage.setItem('notification', JSON.stringify(notification))
		} else if (data.status === 'failed') {
			displayNotification(notification)
			$('#main-loading-spinner').hide()
			return
		}
	}
	
	
	//data.syncid is appended during the serviceWorker handleFailedPost method
    const mock_core_id = data.syncid //undefined if online
	
	//get our room inventory id
	let temp_room_inventory_id = (data.records === undefined) ? mock_core_id : data.records.room_inventory_id;
	
	//barcode_id is either equal to sync_id or barcode_autofill_id
    let barcode_id = Number(form_data.get('barcode_autofill_id')) == 0 ? 
		mock_core_id
		: form_data.get('barcode_autofill_id')
    
	//.records contains all of the ID's from the add-item query, if it's not undefined, then we have results and can use the barcode_id from it.
	if (data.records != undefined) { 
      barcode_id = data.records.barcode_id
	
	//else, check if we have barcode_autofill -- if we do, set the barcode ID to undefined
    } else if (Number(form_data.get('barcode_autofill_id')) == 0) {
      barcode_id = mock_core_id
	 
	//last, use the barcode autofill
    } else {
      barcode_id = form_data.get('barcode_autofill_id')
    }
	
	//check if this was existing room
    const existingRoomInventory = await SktDb.getWithCompoundKey(
      'room-inventory',
      'unique-room-inventory',
      [form_data.get('room_id'), barcode_id, '0']
    )
	
    // User tried to add inv to room that already has that inv, now we add the quantity to the other room
    if (existingRoomInventory !== undefined) {
		existingRoomInventory.quantity = parseInt(existingRoomInventory.quantity) + parseInt(form_data.get('quantity'))
		existingRoomInventory.update_time = new Date().toMysqlFormat()
		await SktDb.add(existingRoomInventory, 'room-inventory')
			const notification = {
			status: 'success',
			message: 'Item already in room, adding to pre-existing quantity instead.'
		}
		
		sessionStorage.setItem('notification', JSON.stringify(notification))
		
		document.location = redirectTo;
		return
		//the return stops any additional processes from running
    }
	
	//if we made it this far, the barcode_id + room_id was unique and we're adding new entries.
    const mock_barcode = {
      id: barcode_id,
      barcode: form_data.get('barcode'),
      product_name: form_data.get('product_name'),
      product_description: form_data.get('product_description'),
      uom_primary_unit_qty: form_data.get('uom_primary_unit_qty'),
      uom_secondary_unit_qty: form_data.get('uom_secondary_unit_qty'),
      uom_primary_unit_type: form_data.get('uom_primary_unit_type'),
      uom_secondary_unit_type: form_data.get('uom_secondary_unit_type'),
	  live: 1,
	  image_url: form_data.get('image_src')
    }

    const mock_room_barcode_alert = {
      id: (data.records === undefined) ? mock_core_id : data.records.room_barcode_alert_id,
      room_id: form_data.get('room_id'),
      barcode_id: barcode_id,
      min_threshold: form_data.get('min_threshold'),
	  max_threshold:
          form_data.get('max_threshold') === '0'
            ? null
            : form_data.get('max_threshold'),
    }

    const mock_room_inventory = {
      id: (data.records === undefined) ? mock_core_id : data.records.room_inventory_id,
      room_id: form_data.get('room_id'),
      barcode_id: barcode_id,
      room_barcode_alert_id: mock_room_barcode_alert.id,
      quantity: form_data.get('quantity'),
      details: form_data.get('details'),
      update_time: new Date().toMysqlFormat(),
      deleted: '0'
    }
		
		console.log(mock_barcode);
		console.log(mock_room_inventory);
		
		// Check if barcode is new or uses existing record, only add if it doesnt already exist.
		if (Number(form_data.get('barcode_autofill_id')) === 0) {
			await SktDb.add(mock_barcode, 'barcode')
		}
		
		//add the new data to our alert and inventory areas
		await SktDb.add(mock_room_barcode_alert, 'room-barcode-alert')
		await SktDb.add(mock_room_inventory, 'room-inventory')

		document.location = redirectTo;
	})

	// Handle saveLastRoom (save last room)
	const activeLocation = JSON.parse(getCookie('active_location'))
	
	if (activeLocation == 'all' || activeLocation.type == 'location' || activeLocation.id == 'all') {
		if (activeLocation.type == 'location') {
		  $('#locations').val(activeLocation.id)
		  setUpItemLocationSelect()
		}
	} else {
		$('#locations').val(activeLocation.location_id)
		setUpItemLocationSelect()
		$('#rooms').val(activeLocation.id)
	}
}
