import SktDb from "./sktDb";
import displayNotification from "./displayNotification";
//import confirmBox from "./confirmBox";

export default async function manageRoomsPage() {
  let data = await SktDb.readAll("room");
  
  data = data.sort(function(a, b) {
	  return a.deleted - b.deleted;
	})

  // Add attributes to results of readAll(room) for the template
  console.log(data);
  if(data.length){
	  await Promise.all(
		data.map(async (item) => {
			
			const location = await SktDb.getRecord("location", item.location_id);
			if(typeof(location) != 'undefined'){
				if(location.live == '1'){
					item.location_name = location.name;
				}
			}
			
		})
	  );
  }

  // Template for rooms to be displayed in an index-like style
  const ManagableRooms = ({
    name,
    location_name,
    description,
    id,
    deleted,
  }) => {
    let button = "";

    if (deleted == true) {
      button = `<span class="status-deleted">Inactive</span>`;
    } else {
      button = `<span class="status-live">Active</span>`;
    }
	
	if(typeof(location_name) == 'undefined'){
		return '';
	} else {
		return `
		  <tr class="room-line-item">
			<td>
				<input class="rooms_action" type="checkbox" name="action_room" value="${id}" />
			</td>
			<td>${button}</button></td>

			<td>${name}</td>
			<td>${location_name}</td>
			<td>${description ?? ""}</td>
			<td><button type="button" href="#" data-id="${id}" class="edit-room"><img class="svg-blue-filter"src="/resources/img/settings-24px.svg"/></button></td>

		  </tr>
	  `;
	}
  };
	
	$("tbody.rooms").on('change','.rooms_action', function (e) {
		
		window.roomsArray = Array();
		$('input:checkbox.rooms_action').each(function () {
			var currentCheckboxVal = this.checked ? $(this).val() : "";

			if (currentCheckboxVal != '') {
				window.roomsArray.push(currentCheckboxVal);
			}
		});

		if (window.roomsArray.length > 0) {
			$('#room-count').html(window.roomsArray.length);

			if (!$('#room-system-submit').hasClass('active')) {
				$('#room-system-submit').addClass('active');
			}
		} else {
			$('#room-system-submit').removeClass('active');
		}

		console.log(window.roomsArray);
	});
	
	// Setup buttons. Process it.
	$("#room-system-submit button").click(async (e) => {
		e.preventDefault();
   
		let formData = new FormData();
		let main_data = window.roomsArray.join(',');
		let action_type =  event.target.getAttribute("data-action");
		let target = '';
		formData.append("room_ids", main_data);
		
		if(action_type == 'active'){
			target = '/onlinerequest/activate-room-inventory';
			
			for (let i = 0; i < window.roomsArray.length; i++) {
				
				//console.log('Resolving IndexedDB for:');
				//console.log(window.roomsArray[i]);
				
				let record = data.find((x) => x.id == window.roomsArray[i]);
				data.find((x) => x.id == window.roomsArray[i]).deleted = 0;
				await SktDb.remove("room", window.roomsArray[i]);
				await SktDb.add(record, "room");
			}
		}else{
			target = '/onlinerequest/delete-room-and-associated-inventory';
			
			//if we're making something inactive, we need them to have confirmed some details
			let confirmZero = $('#confirm_zero').is(':checked');
			if(!confirmZero){
				const notification = {
					status: "failed",
					message: "You cannot make a room inactive until you have confirmed you understand the inventory will be reduced to zero."
				}
				displayNotification(notification);
				
				return false;
			}
			
			for (let i = 0; i < window.roomsArray.length; i++) {
				
				//console.log('Resolving IndexedDB for:');
				//console.log(window.roomsArray[i]);
				
				let record = data.find((x) => x.id == window.roomsArray[i]);
				data.find((x) => x.id == window.roomsArray[i]).deleted = 1;
				await SktDb.remove("room", window.roomsArray[i]);
				await SktDb.add(record, "room");
			}
		}
		
		console.log(target);
		$('#main-loading-spinner').fadeIn();
		
		let response = await fetch(target, {
			method: 'POST',
			body: formData
		});
		
		response = await response.json();
		
		if(response.error){
			$('#main-loading-spinner').fadeOut();
			
			const notification = {
				status: "failed",
				message: response.message
			}
			displayNotification(notification);
			
		}else{
			
			$('#main-loading-spinner').fadeOut();
			if(typeof(response.ri_ids) != 'undefined'){
				if(response.ri_ids.length > 0){
					for (let i = 0; i < response.ri_ids.length; i++) {
						SktDb.remove("room-inventory", response.ri_ids[i]);
					}
				}
			}
			
			window.location.href = "/manage/rooms";
		}
    }); 
	
	// Template for Locations to be added to drop-down in room creation
	const Location = ({ id, name, live }) => {
		if(live){
			return `<option value="${id}">${name}</option>`;
		}
	}

  // Adds locations with the template to the dropown.
  const locations = await SktDb.readAll("location");
  $(".input-wrapper select").append(locations.map(Location).join(""));

  // Adds rooms to view with the template
  $("tbody.rooms").append(data.map(ManagableRooms).join(""));

  // Change 'add-room' form to an edit room form.
  $("tbody.rooms").on("click", ".edit-room", async (e) => {
    window.scrollTo(0, 0);
    const room_id = $(e.currentTarget).data("id");
    const room = await SktDb.getRecord("room", room_id);
    $("#edit-room-form input[name='name']").val(room.name);
    $("#edit-room-form select").val(room.location_id);
    $("#edit-room-form input[name='description']").val(room.description);
    $("#room-id-edit").val(room_id);
    if ($("#edit-room-form").is(":hidden"))
      $(".room-toggle-visibility").toggle();
  });

  // Status Button
  $("tbody.rooms").on("click", ".delete-room", async (e) => {
    e.preventDefault();
    const room_id = $(e.currentTarget).data("id");
    let record = data.find((x) => x.id == room_id);
    console.log(record);
    console.log(data);

    if (record.deleted == false) {
      record.deleted = 1;
      console.log(data);
      data.find((x) => x.id == room_id).deleted = 1;
      if (
        confirm(
          "Are you sure you want to archive this Room? You can reverse this action but it may take significant time."
        )
      ) {
        const response = await fetch("/api/delete-room/" + room_id, {
          method: "DELETE",
        });
        const data = await response.json();
        if (data.message) {
          const notification = {
            status: data.status,
            message: data.message,
          };
          if (data.status === "success") {
            sessionStorage.setItem(
              "notification",
              JSON.stringify(notification)
            );
          } else if (data.status === "failed") {
            displayNotification(notification);
            $("#main-loading-spinner").hide();
            return;
          }
        }
        await SktDb.remove("room", room_id);
        await SktDb.add(record, "room");
        $(e.currentTarget).prop("checked", false);
        location.reload();
      }
    } else {
      record.deleted = 0;
      data.find((x) => x.id == room_id).deleted = 0;
      record.room_id = record.id;
      console.log(record);
      let form_data = new FormData();
      form_data.append("name", record.name);
      form_data.append("location_id", record.location_id);
      form_data.append("room_id", record.id);

      const response = await fetch("/api/edit-room/", {
        method: "POST",
        body: form_data,
      });
      const res = await response.json();
      if (res.message) {
        const notification = {
          status: res.status,
          message: res.message,
        };
        if (res.status === "success") {
          sessionStorage.setItem("notification", JSON.stringify(notification));
        } else if (res.status === "failed") {
          displayNotification(notification);
          $("#main-loading-spinner").hide();
          return;
        }
      }
      await SktDb.remove("room", room_id);
      await SktDb.add(record, "room");
      $(e.currentTarget).prop("checked", true);

      location.reload();
    }
  });

  // Toggle back to create room setup
  $("#cancel-edit").on("click", (e) => {
    $(".room-toggle-visibility").toggle();
  });

  // Room creation submit through js
  $("form#add-room-form").on("submit", async (e) => {
    e.preventDefault();
    $("#main-loading-spinner").fadeIn();
    const formElement = $("form#add-room-form")[0];
    const form_data = new FormData(formElement);
    const response = await fetch("/api/add-room", {
      method: "POST",
      body: form_data,
    });
    const data = await response.json();
    if (data.message) {
      const notification = {
        status: data.status,
        message: data.message,
      };
      if (data.status === "success") {
        sessionStorage.setItem("notification", JSON.stringify(notification));
      } else if (data.status === "failed") {
        displayNotification(notification);
        $("#main-loading-spinner").hide();
        return;
      }
    }
	
	console.log(data);
	
	const mock_room = {
      id: data.id,
      name: form_data.get('name'),
      location_id: form_data.get('location_id'),
      description: form_data.get('description'),
      deleted: '0'
    }
	
    await SktDb.add(mock_room, "room");
    location.reload();
  });

  // Room edit submit through js
  $("form#edit-room-form").on("submit", async (e) => {
    e.preventDefault();
    $("#main-loading-spinner").fadeIn();
    const formElement = $("form#edit-room-form")[0];
    const form_data = new FormData(formElement);
    const room_id = form_data.get("room_id");
    for (var value of form_data.values()) {
      console.log(value);
    }

    const response = await fetch("/api/edit-room", {
      method: "POST",
      body: form_data,
    });
    const data = await response.json();
    if (data.message) {
      const notification = {
        status: data.status,
        message: data.message,
      };
      if (data.status === "success") {
        sessionStorage.setItem("notification", JSON.stringify(notification));
      } else if (data.status === "failed") {
        displayNotification(notification);
        $("#main-loading-spinner").hide();
        return;
      }
    }

    const room = await SktDb.getRecord("room", room_id);
    room.name = form_data.get("name");
    room.location_id = form_data.get("location_id");
    room.description = form_data.get("description");
    await SktDb.add(room, "room");
    location.reload();
  });
  
  
  
	//EDIT a Location
	$("tbody.locations").on("click", ".edit-location", async (e) => {
		const location_id = $(e.currentTarget).data("id");
		const this_location = await SktDb.getRecord("location", location_id);

		console.log(location_id);
		console.log(this_location);

		$(".add-edit-location h2").html("Edit Location");
		$("#edit-location-form input[name='name']").val(this_location.name);
		$("#edit-location-form input[name='description']").val(this_location.description);
		$("#location-id-edit").val(location_id);
		$("#location-submit").val('Update');
		$("#cancel-location").show();
	});
  
	$('#cancel-location').on("click", async (e) => {
		e.preventDefault();
		$(".add-edit-location h2").html("Add Location");
		$("#edit-location-form input[name='name']").val('');
		$("#edit-location-form input[name='description']").val('');
		$("#location-id-edit").val('');
		$("#location-submit").val('Create');
		$("#cancel-location").hide();
	});
	
	// Status Button
	$("tbody").on("click", ".delete-location", async (e) => {
		e.preventDefault();
		//test
		let location_id = $(e.currentTarget).data("id");
		let confirmDelete = $(e.currentTarget).data("action");
		if(confirmDelete == '_wait'){
			let action = confirmBox({
				'title'     : 'Delete Location',
				'message'   : "You are about to delete a location. This requires all inventory associated to the location to be reduced to zero. Once a location has been deleted, the action cannot be undone.",
				}, location_id);
			
			return
		}
    });
	
	
	function confirmBox (details, location_id) {
		
		$('#confirmBox').show();
		$('#confirmBox h2').html(details.title);
		$('#confirmBox p').html(details.message);
		
		//check for click
		$('#confirmButtons .btn').on('click', async function(e){
			$('#confirmBox').hide();
				
			if($(this).data('value') == true){
					
				$("#main-loading-spinner").fadeIn();

				const response = await fetch("/onlinerequest/delete-location?id=" + location_id, {
				  method: "POST",
				});
				const data = await response.json();
				
				if(data.error || data.error == 'true'){
					
					const notification = {
						status: 'failed',
						message: data.message,
					};
					sessionStorage.setItem("notification", JSON.stringify(notification));
				}else{
					
					const notification = {
						status: 'success',
						message: data.message,
					};
					
					sessionStorage.setItem("notification", JSON.stringify(notification));
					await SktDb.remove("location", location_id);
					
				}
				location.reload();
				
			}else{
				return false;
			}
		});
	}
}
