// Will handle jquery listeners for home dropdown menu



// Up/Down icons
let down = '<img src="/resources/img/iconmonstr-angel-down-circle-thin-32.png" alt="Open Menu" />';
let up = '<img src="/resources/img/iconmonstr-angel-up-circle-thin-32.png" alt="Close Menu" />';

let seeMoreElement = '<div id="see-more" class="card-item-container-wide"><div class="card-image-container"></div><p>See More....</p></div>'
let seeLessElement = '<div id="see-less" class="card-item-container-wide"><div class="card-image-container"></div><p>See Less....</p></div>'


// Handle external menus opening/closing
$(document).on("click",".collapsible-menu h2", function () {
    var clickedBtnID = $(this).siblings('.menu-content'); // or var clickedBtnID = this.id
    // alert('you clicked on button #' + clickedBtnID);
    // $('.menu-content').removeClass('active')

    if (clickedBtnID.hasClass('active')) {
        clickedBtnID.removeClass('active')
        $(this).children('.open-close-icon').html(up)
    } else {
    clickedBtnID.addClass('active')
    $(this).children('.open-close-icon').html(down)

    }
 });

 // Declare 


 $(document).ready(() => {
    
    
    

})

function seeMore() {
    let storedElements = []
    let list = $('#list-container')
    let childList = list.children()
    if (childList.length > 3) {
        console.log('slice')
        for (let i = 3; i < childList.length; i++) {
            storedElements.push(childList[i])
            childList[i].remove()
        }
    }
    list.append(seeMoreElement)

    $('#see-more').on('click', () => {
        $('#see-more').remove()
        for (let y = 0; y < storedElements.length; y++) {
            list.append(storedElements[y])
        }
        list.append(seeLessElement)

        $('#see-less').on('click', () => {
            $('#see-less').remove()
            seeMore()
        })
    })

    
}