// Sets cookie that lasts for a year, many of our settings use a year long exp so it made sense to make this function
// const setLongLastingCookie = (cookieName, value) => {
//   const expiration_date = new Date()
//   expiration_date.setFullYear(expiration_date.getFullYear() + 1)
//   const cookie_string = cookieName + '=' + JSON.stringify(value) + '; path=/; expires=' + expiration_date.toUTCString()
//   document.cookie = cookie_string
// }

export default function findGetParameter (parameterName) {
  let result = null; let tmp = []
  location.search
    .substr(1)
    .split('&')
    .forEach(function (item) {
      tmp = item.split('=')
      if (tmp[0] === parameterName) { result = decodeURIComponent(tmp[1]) }
    })
  return result
}
