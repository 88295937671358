import SktDb from './sktDb';

export default async function renderTransferData() {
	/****
	**
	** This function is run anytime the transfer card is being impacted by user actions.
	** The goal is to provide detailed information regarding the transfer to the user,
	** so that they can make an informed choice on what this transaction will do.
	**
	****/
	
	
	//we need to get some core values to start
	let start_display = '';
	let end_display = '';
	
	let start_room_id = $('#start-room').val();
	let end_room_id = $('#room-list').val();
	
	let start_room_name = $('#start-room').text();
	let end_room_name = $("#room-list option[value='"+end_room_id+"'][data-type='room']").text().trim();
	
	let transfer_quantity = $('#transfer_quantity').val();
	
	//default transfer to 0 if we don't have a number value
	if(transfer_quantity == '' || typeof(transfer_quantity) == 'undefined'){
		transfer_quantity = 0;
	}
	
	//get our starting room-inventory record:
	let start_room_inventory = await SktDb.getRecord('room-inventory', $('#current_room_inventory_id').val());
	let start_room_details = await SktDb.getRecord('room', start_room_inventory.room_id);
	console.log('Start Room Inventory');
	console.log(start_room_inventory);
	
	//get our current target barcode_id from this record.
	let barcode_id = start_room_inventory.barcode_id;
	
	//get our barcode details for display to the user
	let barcode_details = await SktDb.getRecord('barcode', barcode_id);
	
	//SktDb.getRecord requires the room-inventory id, which... we don't have for the end room. We have room_id and barcode_id instead, so we need to get all of the records and then filter them down to see if we have a result.
	const all_room_inventory_data = await SktDb.readAll('room-inventory');
	
	//filter our barcode first
	let fri = all_room_inventory_data.filter(inv => inv.barcode_id == barcode_id);
	
	//then get the start and end room inventories using the room id's
	let end_room_inventory = fri.filter(inv => inv.room_id == end_room_id);
	
	console.log('End Room Inventory');
	console.log(end_room_inventory);
	
	//create our end display
	if(end_room_inventory.length == 0){
		console.log('New Inventory Record');
		
		end_display = `
			<div class="transfer_end">
				<h3>${end_room_name} Details</h3>
				<ul>
					<li>
						Status: New Record
					</li>
					<li>
						Item: ${barcode_details.product_name}
					</li>
					<li>
						Quantity: ${transfer_quantity}
					</li>
				</ul>
			</div>
		`;
	}else{
		console.log('Update Inventory Record');
		end_room_inventory = end_room_inventory[0];
		
		end_display = `
			<div class="transfer_end">
				<h3>${end_room_name} Details</h3>
				<ul>
					<li>
						Status: Update Record
					</li>
					<li>
						Item: ${barcode_details.product_name}
					</li>
					<li>
						Quantity: ${parseFloat(end_room_inventory.quantity) + parseFloat(transfer_quantity)}
					</li>
				</ul>
			</div>
		`;
	}
	
	//create our start display
	start_display = `
		<div class="transfer_start">
			<h3>${start_room_details.name} Details</h3>
			<ul>
				<li>
					Status: Update Record
				</li>
				<li>
					Item: ${barcode_details.product_name}
				</li>
				<li>
					Quantity: ${parseFloat(start_room_inventory.quantity) - parseFloat(transfer_quantity)}
				</li>
			</ul>
		</div>
	`;
	
	let details = start_display + end_display;
	
	//add these to the details area
	$('.thedetails').html(details);
	$('#transfer_title').html('Transfer Item: '+barcode_details.product_name);
}
