import getCookie from "./getCookie";

export default function tooltipHandler() {
  jQuery(function ($) {
    //On hover on id create tooltip element and append to that div
    $('#pwa__core').on('mouseover', '.tooltip',
      function (e) {
		
		//smart detection if the tooltip is part of the menu, and the menu is currently expanded
		if($(this).closest(".expanded-menu-container").length > 0 && !$('body').hasClass('collapsed-side-nav')){
			return false;
		}
		  
		let showTooltips = getCookie("show_tooltips");
		if(showTooltips == 'true' || $(this).hasClass('force-tooltip')){
		  
			let text = $(this).data('tooltip');
			let currentMousePosX = e.pageX - 250;
			let currentMousePosY = e.pageY - 24;

			$('#pwa__core').append(`<div class="tooltip-display">${text}</div>`);

			//display the tooltip with animation.
			$('#pwa__core').find('.tooltip-display').hide().fadeIn('slow');

			// Handle Window Exceptions

			let tooltipWidth = $('#pwa__core').find('.tooltip-display').width();
			let tooltipHeight = $('#pwa__core').find('.tooltip-display').height();
			
			tooltipWidth += 72;
			tooltipHeight += 16;


			if (e.pageX < tooltipWidth && e.pageY < tooltipHeight) {
			  $('#pwa__core')
				.find('.tooltip-display')
				.css({
				  top: currentMousePosY + tooltipHeight + 'px',
				  left: currentMousePosX + tooltipWidth + 'px',
				});
			} else if(e.pageY < tooltipHeight) {
			  $('#pwa__core')
				.find('.tooltip-display')
				.css({
				  top: currentMousePosY + tooltipHeight + 'px',
				  left: currentMousePosX + 'px',
				});
			} else if(e.pageX < tooltipWidth) {
			  $('#pwa__core')
				.find('.tooltip-display')
				.css({
				  top: currentMousePosY + 'px',
				  left: currentMousePosX + tooltipWidth + 'px',
				});
			} else {
			  $('#pwa__core')
				.find('.tooltip-display')
				.css({
				  top: currentMousePosY + 'px',
				  left: currentMousePosX + 'px',
				});
			}
		}
      });

      //On hover out remove the tooltip.
     $('#pwa__core').on('mouseout', '.tooltip',
      function (e) {
        $('#pwa__core').find('.tooltip-display').remove();
      }
    );
  });
}
