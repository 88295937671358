import SktDb from './sktDb';
import ItemSort from './itemSort';
import getLocationsAndRoomsForHTML from './getLocationsAndRoomsForHTML';
import getCookie from './getCookie';
import getNotificationColor from './getNotificationColor';
import quantityColor from './quantityColor';
import transferSubmit from './transferSubmit';
import updateEditQuantityCard from './updateEditQuantityCard';
import findGetParameter from './findGetParameter';
import renderTransferData from './renderTransferData';

// globals
import {
  LOW_ALERT_QUANTITY_MOD,
  OVERSTOCK_QUANTITY_MOD,
  ADD,
  SUBTRACT,
  setItemQuantityAdjustment,
} from './globals';

export default async function prepareItemsIndexPage(filterSettings = null) {
	sessionStorage.setItem('itemBackButton', 'items');
	
  // Template for js mapping
  const NewItem = ({ barcode, room_barcode_alert, quantity, id, room }) => {
	 let notificationColor = 'red';
	 let classColor = '';
	
	if(typeof(room_barcode_alert) != 'undefined'){
		notificationColor = getNotificationColor(
		  quantity,
		  room_barcode_alert.min_threshold,
		  room_barcode_alert.max_threshold
		);
		
		classColor = quantityColor(
			quantity,
			room_barcode_alert.min_threshold
		);
	}else{
		let room_barcode_alert = Object();
		room_barcode_alert.min_threshold = 0;
		room_barcode_alert.max_threshold = 0;
	}
    // Notice alert type sets to - even if the room is also overstocked (Yes it can be both, should change this later) this is because its more important that the user knows if its low.
	
	let alertTypeIndicator = '';	
	let notificationIcon = notificationColor;
	let showNotice = true;
	if(notificationColor == 'none'){
		showNotice = false;
	}
	if(notificationColor == 'green-plus'){
		alertTypeIndicator = '+';
		notificationColor = 'green';
	} 
	if(notificationColor == 'red'){
		alertTypeIndicator = '-';
	}
	
    const barcodeSku =
      getCookie('show_barcode_sku') === 'true'
        ? `<p class="barcode">${
            barcode.barcode.length > 0 ? barcode.barcode : 'No Barcode'
          }</p>`
        : '';
		
    return `
      <a class="item-card ${mode}-item" data-id="${id}" data-quantity="${quantity}" data-room="${
      room.id
    }">
          <div class="item-image" ${
            barcode.image_url
              ? "style='background:url(\"" +
                barcode.image_url +
                "\") no-repeat center;background-size:cover;'"
              : ''
          }>
          </div>
          <div class="item-details">
				<p class="room">${
					room !== undefined ? room.name : 'Deleted Room'
				}</p>
				<p class="name">${barcode.product_name || 'Undefined'}</p>
				${barcodeSku}
				<p class="quantity">Quantity: <span class="mont-bold ${classColor}">
					${quantity} 
					<span class="this_uom">
						${barcode.uom_primary_unit_type != null ? barcode.uom_primary_unit_type.toUpperCase() : ''}
					</span>
				</span>
			</p>
          </div>
		  
          <div class="stock-indicator ${showNotice == false ? 'hideNotice' : ''}">
              <div class="alert-type-indicator text-${notificationColor}">
				${alertTypeIndicator}
              </div>
              <img src="/resources/img/${notificationIcon}_notification.png" />
          </div>
      </a> 
    `;
  };
  const BarcodeItem = (barcode, index, total) => `
    <div data-current-barcode-index="${index}" class="item-top-card">
        <div id="barcode-pagination">
          <a id="previous-barcode"><- </a><span>${
            index + 1
          } / <span id="total_items">${total}</span></span><a id="next-barcode"> -></a>
        </div>
        <div class="item-image hide-when-offline" ${
          barcode.image_url
            ? 'style="background:url(' +
              barcode.image_url +
              ') no-repeat center;background-size:cover;"'
            : ''
        }">
        </div>
        <a id="add-new-inventory" href="/add-item?id=${barcode.id}">
          <img src="/resources/img/plus_icon.png">
        </a>

        <div class="input-wrapper" style="clear:both;">
            <label for="barcode">SKU/UPC</label>
            <input id="barcode-input-form-field" class="locked-input" readonly type="text" name="barcode" value="${
              barcode.barcode
            }">
        </div> 
        <div class="input-wrapper">
            <label for="product_name">Item Name</label>
            <input type="text" name="product_name" readonly class="locked-input" value="${
              barcode.product_name
            }">
        </div>
        <div class="input-wrapper mb-0">
            <label for="product_description">Item Description</label>
            <input type="text" name="product_description" readonly class="locked-input" value="${
              barcode.product_description
            }">
        </div>
    </div>
    <div class="card" id="uom-card">
        <h2>Unit of Measure</h2>
        <div class="card-body wide-body">
            <div class="double-input-row">
                <div class="input-wrapper">
                    <label for="uom_primary_unit_qty">Number Of Unit</label>
                    <input type="number" name="uom_primary_unit_qty" class="locked-input" readonly value="${
                      barcode.uom_primary_unit_qty
                    }">
                </div><!-- leave in to counteract inline-block white space
                --><div class="input-wrapper">
                    <label for="uom_secondary_unit_qty">Qty of Unit</label>
                    <input type="number" name="uom_secondary_unit_qty" class="locked-input" readonly value="${
                      barcode.uom_secondary_unit_qty
                    }">
                </div>
            </div>
            <div class="double-input-row">
                <div class="input-wrapper">
                    <label for="uom_primary_unit_type">Unit Type</label>
                    <input type="number" name="uom_primary_unit_type" class="locked-input" readonly value="${
                      barcode.uom_primary_unit_type
                    }">
                </div><!-- leave in to counteract inline-block white space
                --><div class="input-wrapper">
                    <label for="uom_secondary_unit_type">Unit Other</label>
                    <input type="number" name="uom_secondary_unit_type" class="locked-input" readonly value="${
                      barcode.uom_secondary_unit_type
                    }">
                </div>
            </div>
        </div>
    </div>
    `;
  $('#barcode-item-container').on('click', '#next-barcode', () => {
    const new_index =
      ($('.item-top-card').data('current-barcode-index') + 1) %
      barcodeItems.length;
    $('#barcode-item-container').html(
      BarcodeItem(barcodeItems[new_index], new_index, barcodeItems.length)
    );
  });
  $('#barcode-item-container').on('click', '#previous-barcode', () => {
    const new_index =
      ((($('.item-top-card').data('current-barcode-index') - 1) %
        barcodeItems.length) +
        barcodeItems.length) %
      barcodeItems.length;
    $('#barcode-item-container').html(
      BarcodeItem(barcodeItems[new_index], new_index, barcodeItems.length)
    );
  });
  let barcodeItems = [];

  // Check if url param mode is set EG: pull/restock
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const mode = urlParams.get('mode') || 'default';

  // Get ID of room for filtering.
  let active_location_or_room_id;
  let active_type;
  try {
    active_location_or_room_id = JSON.parse(getCookie('active_location')).id;
    active_type = JSON.parse(getCookie('active_location')).type;
  } catch (e) {
    active_type = 'all';
  }

  let items = await SktDb.readAll('room-inventory');
  items = items.filter((item) => {
    return item.deleted == '0';
  });

  await Promise.all(
    items.map(async (item) => {
      const barcode = await SktDb.getRecord('barcode', item.barcode_id);
      const room_barcode_alert = await SktDb.getRecord(
        'room-barcode-alert',
        item.room_barcode_alert_id
      );
      const room = await SktDb.getRecord('room', item.room_id);
      item.room = room;
      item.barcode = barcode;
      item.room_barcode_alert = room_barcode_alert;
    })
  );

  // TODO change search logic to return exact match at top and then the partial matches
  if (urlParams.get('search') !== null) {
    const searchTerm = decodeURIComponent(urlParams.get('search'));
    //$('.main-search').val(searchTerm); Per Steve L, Remove this.
    const searchedItems = new ItemSort(items);
	items = searchedItems.search(searchTerm).getItems();
	console.log(items);
	if(items.length == 0){
		//no results
	}
	else{
		
		barcodeItems = await SktDb.readAll('barcode');
		const barcodeBarcodeMatches = barcodeItems.filter((barcode) =>
		  barcode.barcode.includes(searchTerm)
		);
		const barcodeProductNameMatches = barcodeItems.filter((barcode) =>
		  barcode.product_name.toLowerCase().includes(searchTerm.toLowerCase())
		);
		//console.log(barcodeBarcodeMatches);
		const barcodeItemsResults = [
		  ...barcodeBarcodeMatches,
		  ...barcodeProductNameMatches,
		];
		barcodeItems = [...new Set(barcodeItemsResults)];
	}
  }
  if (active_type === 'location') {
    items = new ItemSort(items).filterLocation(active_location_or_room_id);
  } else if (active_type === 'room') {
    items = new ItemSort(items).filterRoom(active_location_or_room_id);
  } else {
    items = new ItemSort(items).filterLocation('all');
  }

  // Filter out archived items
  items = items.items;


  items = new ItemSort(items).filterArchived(true);

  if (filterSettings != null) {
    if (filterSettings.sort != null) {
      items.sort(filterSettings.sort);
    }
    if (filterSettings.filter != null) {
      items.filter(
        filterSettings.filter,
        LOW_ALERT_QUANTITY_MOD,
        OVERSTOCK_QUANTITY_MOD
      );
    }
    if (filterSettings.sortdirection === 'descending') {
      items.reverse();
    }
    if (filterSettings.roomFilter != null) {
      items.filterRoom(filterSettings.roomFilter);
    }
  } else {
    items.sortLastUpdated();
  }

  const locations_and_rooms = await getLocationsAndRoomsForHTML(true);
  const html =
    '<option value="all">Click to Select Room</option>' + locations_and_rooms;
  $('#filter-container #room-select').html(html);
  const activeLocationIndex = Number(
    JSON.parse(getCookie('active_location')).selectIndex
  );
  $('#filter-container #room-select option')
    .eq(activeLocationIndex)
    .prop('selected', true);
  let autoloadID = 0;

  if (mode != null) {
    switch (mode) {
      case 'default':
        $('body').on('click', '.default-item', (e) => {
          const room_inventory_id = $(e.currentTarget).data('id');
          window.location = '/edit-item?id=' + room_inventory_id;
        });
        break;

      case 'pull':
        $('#items-index-mode').text('Pulling Items');
        $('#quantity-card-header').html('Pulling Inventory');
        $('body').on('click', '.pull-item', (e) => {
          const room_inventory_id = $(e.currentTarget).data('id');
		  let itemName = $(e.currentTarget).find('.name').text();
		  let itemNumber = $(e.currentTarget).find('.barcode').text();
		  let itemRoom = $(e.currentTarget).find('.room').text();
		  let itemUOM = $(e.currentTarget).find('.this_uom').text();
		  
          $('#adjust-quantity-card').find('input:text').val('');	  
          $('#adjust-quantity-card h3').html('Pulling in: ' + itemRoom);
		  $('#add-button-in-card').hide();
		  $('#subtract-button-in-card').show();
		  $("input[name='item-id']").val(room_inventory_id);
		  $('#add-subtract-input input').val(0);
		  
		  let theaction = `<div class="input-wrapper mb-0">
					<label for="actiontypejs">Action Type</label>
					<select name="actiontype" id="actiontypejs">
						<option value="general">General Use</option>
						<option value="waste">Waste</option>
					</select>
				</div>`;
			
			let thedetails = `
				<ul>
					<li>Item Name: ${itemName} </li>
					<li>Item Number: ${itemNumber}</li>
					<li>Item UOM: ${itemUOM}</li>
				</ul>
			`
			
			$(".thedetails").html(thedetails);
				
			$(".theaction").html(theaction);
          $(
            "#adjust-quantity-card input[name='quantityjs'], #adjust-quantity-card input[name='oldquantity']"
          ).val($(e.currentTarget).data('quantity'));
          setItemQuantityAdjustment(SUBTRACT);
          updateEditQuantityCard();
          $('#adjust-quantity-card').removeClass();
          $('#adjust-quantity-card').addClass('subtract-active add-disable');
          $('.dark-screen-top, #adjust-quantity-card').show();
		  $('#add-subtract-input input').focus();
		  
		  window.redirectLocation = '/items?mode=pull';
        });
		
		if (
          items.getItems().length === 1 &&
          findGetParameter('search') !== null
        ) {
          autoloadID = items.getItems()[0].id;
        }
		
        break;

      case 'restock':
        $('#items-index-mode').text('Restocking Items');
        $('#quantity-card-header').html('Restocking Inventory');
        $('body').on('click', '.restock-item', (e) => {
          const room_inventory_id = $(e.currentTarget).data('id');
          $('#adjust-quantity-card').find('input:text').val('');
          $(
            "#adjust-quantity-card input[name='quantityjs'], #adjust-quantity-card input[name='oldquantity']"
          ).val($(e.currentTarget).data('quantity'));
          setItemQuantityAdjustment(ADD);
		  
			let itemName = $(e.currentTarget).find('.name').text();
			let itemNumber = $(e.currentTarget).find('.barcode').text();
			let itemRoom = $(e.currentTarget).find('.room').text();
			let itemUOM = $(e.currentTarget).find('.this_uom').text();
		  
			$('#adjust-quantity-card h3').html('Restocking in: ' + itemRoom);
			$('#add-button-in-card').show();
			$('#subtract-button-in-card').hide();
			$("input[name='item-id']").val(room_inventory_id);
			$('#add-subtract-input input').val(0);

			let theaction = `<input name="actiontype" id="actiontypejs" type="hidden" value="addition" />`;

			let thedetails = `
				<ul>
					<li>Item Name: ${itemName} </li>
					<li>Item Number: ${itemNumber}</li>
					<li>Item UOM: ${itemUOM}</li>
				</ul>
			`
			
			$(".thedetails").html(thedetails);
			$(".theaction").html(theaction);
			
          updateEditQuantityCard();
          $('#adjust-quantity-card').removeClass();
          $('#adjust-quantity-card').addClass('add-active subtract-disable');
          $('.dark-screen-top, #adjust-quantity-card').show();
		  $('#add-subtract-input input').focus();
		  
		  window.redirectLocation = '/items?mode=restock';
        });
		
		if (
          items.getItems().length === 1 &&
          findGetParameter('search') !== null
        ) {
          autoloadID = items.getItems()[0].id;
        }
		
        break;

      case 'transfer':
		
        $('#transfer-card').on('submit', (e) => {
			console.log(window.transferCount);
			if(window.transferCount === 1){
				transferSubmit(e, '/items?mode=transfer');
				window.transferCount = window.transferCount + 1;
			}else{
				console.log('Duplicate prevented');
			}
        });
        $('#items-index-mode').text('Transferring Items');
		
		//trigger the transfer render when the room changes
		$('body').on('change', '#room-list', async (e) => {
			renderTransferData();
		});
		
		//trigger the transfer render when the transfer value changes
		$('body').on('keyup', '#transfer_quantity', async (e) => {
			renderTransferData();
		});
		
		//trigger data collection and rendering when the transfer card is clicked
        $('body').on('click', '.transfer-item', async (e) => {
			//set-up some variables
			const quantity = $(e.currentTarget).data('quantity');
			const current_room_id = $(e.currentTarget).data('room');
			const current_room_inventory_id = $(e.currentTarget).data('id');
			let itemUOM = $(e.currentTarget).find('.this_uom').text();
			
			//populate some basic data
			$("#transfer_uom").html('('+itemUOM+')');
			$("input[name='current_room_inventory_id']").val(current_room_inventory_id);
			$('#add-subtract-input input').val(0);
			$('#add-subtract-input input').attr('max', quantity);
			
			//get our rooms and locations
			const data = await getLocationsAndRoomsForHTML();
			
			//populate our 'end room' options
			$('#transfer-card #room-list').html(data);
			
			//remove our current room from the 'end room' since we can't transfer to ourselves
			$(
			"#transfer-card #room-list option[value='" +
			  current_room_id +
			  "'][data-type='room']"
			).remove();
			
			//get our current room information
			const room = await SktDb.getRecord('room', current_room_id);
			
			//populate our start room value
			$("input[name='start_room']").val(room.name);
			
			//show the card
			$('.dark-screen-top, #transfer-card').show();
			$('#add-subtract-input input').focus();
			
			//note that we are in the transfer system
			window.transferCount = 1;
			
			//render start and end user experience blocks
			renderTransferData();
        });
		
		if (
          items.getItems().length === 1 &&
          findGetParameter('search') !== null
        ) {
          autoloadID = items.getItems()[0].id;
        }
		
        break;

      case 'audit':
        $('#items-index-mode').text('Auditing Item');
		$('#add-subtract-input input').val(0);

        $('body').on('click', '.audit-item', async (e) => {
			
		  let itemUOM = $(e.currentTarget).find('.this_uom').text();
		  $("#audit_uom").html('('+itemUOM+')');
			
		  let itemRoom = $(e.currentTarget).find('.room').text();
		  $('#audit-card h3').html('Auditing in: ' + itemRoom);
		  
          //$('#items-audit-room').text($(e.currentTarget).find('.room').text());
          $('#items-audit-amount').val(
            $(e.currentTarget).find('.quantity .mont-bold').text()
          );

          $("input[name='item-id']").val($(e.currentTarget).data('id'));
          $('#audit-card').find('input:text').val('');
		  
		  //we want to keep details clearly available for the user, so make notes for them:
			let itemName = $(e.currentTarget).find('.name').text();
			let itemNumber = $(e.currentTarget).find('.barcode').text();
			
			let thedetails = `
					<ul>
						<li>Item Name: ${itemName} </li>
						<li>Item Number: ${itemNumber}</li>
						<li>Item Room: ${itemRoom}</li>
						<li>Item UOM: ${itemUOM}</li>
					</ul>
				`;
				
			$(".thedetails").html(thedetails);

          $('.dark-screen-top, #audit-card').show();
		  $('#add-subtract-input input').focus();
		  
		  window.redirectLocation = '/items?mode=audit';
        });

        if (
          items.getItems().length === 1 &&
          findGetParameter('search') !== null
        ) {
          autoloadID = items.getItems()[0].id;
        }

        break;
    }
  }
  
  //WRITE THE DATA TO THE PAGE
  //	[brk] 10 aug 2022: So while the IF-stmt below did get executed, it was doing so improperly up until now.
  //	We had been just checking, items.length. But the root Object, items, didn't have a length property - it was always undefined.
  //	Examining the object, it turns out it had a sub-object *also* called items, which was an array and did have a length property.
  //	So the following now allows the ELSE part below to properly execute.
  if (items.items.length !== 0) {
    if (mode !== 'default') {
      $('#items-index .item-container').html(
        items.getItems().map(NewItem).join('')
      );
    } else {
	  //console.log(items.getItems().map(NewItem).join(''));
	  
      $('#items-index .item-container').html(
        items.getItems().map(NewItem).join('')
      );
    }
	
	//console.log("barcodes length");
	//console.log(barcodeItems.length);
	
    if (barcodeItems.length > 0) {
      $('#items-index .item-container').addClass('columned-items-with-barcode');
      $('#items-index #barcode-item-container').empty();
      $('#items-index #barcode-item-container').append(
        BarcodeItem(barcodeItems[0], 0, barcodeItems.length)
      );
      //console.log('block added');
    } else {
		//	[brk] 10 aug 2022 : We need to snoop a bit more, but I don't think we need this here, as I don't think there will ever be a time it executes.
		//	But I could be wrong. You'll see it copied below and that seems to work. Again, we should run more test cases, so I'm leaving it here for now.
		const searchTerm = decodeURIComponent(urlParams.get('search'));
			if(searchTerm != 'null'){
			console.log("No Search Results");
			$('.item-container').append(
				`<div id="items-placeholder">
					<h2>No Search Results</h2>
					<p>The search for ${searchTerm} did not return any results for <br/> the current room/location.</p>
					<p><a href="/add-item">Add Item</a></p>
				</div>`
			);
			
		}else{
			$('#items-index .item-container').addClass('columned-items');
		}
    }
  } else {
	  //	[brk] 10 Aug 2022: We actually get here not due to the barcodeItems.length being zero, as was assumed above. But rather
	  //	the items.items.length being zero, which indicates that the searched for item (which exists in the barcodeItems object) is not in the current room.
		const searchTerm = decodeURIComponent(urlParams.get('search'));
			if(searchTerm != 'null'){
				$('#items-index .item-container').empty();
				console.log("No Search Results 2");
				$('.item-container').append(
					`<div id="items-placeholder">
						<h2>No Search Results</h2>
						<p>The search for ${searchTerm} <br/> did not return any results for the current room/location. <br/> It may exist in another room/location.</p>
						<p>Or you can <a href="/add-item">Create/Add the Item</a> to this room</p>
					</div>`
				);
			}else{
				//if we have items, add columns
				if(barcodeItems.length != 0){
					$('#items-index .item-container').addClass('columned-items');
				}else{
					$('#items-index .item-container').removeClass('columned-items');
					$('#items-index .item-container').html(
						`<div id="items-placeholder">
				
							<h2>There are no items.</h2>
							<p>Let’s get started! Press + to<br />add your first item.</p>
							
						</div>`
					)
				}
			}
    }

  if (autoloadID !== 0) {
    // $('.dark-screen-top, #location-select-card').hide()
    $('body')
      .find('[data-id=' + autoloadID + ']')
      .trigger('click');
  }
}
