import populateBarcodeInfo from './populateBarcodeInfo';
import mainSearchExecute from './mainSearchExecute';
import SktDb from './sktDb'
import { isValidUpcOrEan, apiLookup } from './upcAPI';
import { data } from 'jquery';

export default function smartLookUp(force = false) { 
    // This system is designed for use on the add-item screen. It's used to look-up items, handle scanning, and overall create a smooth user experience.
	
	// The process for this look-up should be handled in three key steps:
	/*
		1) When we have 3 characters, we need to parse data from IndexedDB for the client db
			- Output first 5 results of this for user review
		2) Pass this full array to the global barcode look-up. To do a NOT IN query and get the full results.
		3) Lastly, we need to handle auto-complete, barcode scanners, and the likes.
	*/
	
	// Let's create a simple system that calls larger functions to keep this process neat and modularized.
	
	$('#barcode-input-form-field').on('keyup', async function(e){
		console.log("Started KeyUp Logging");
		
		handleKeyPress(e);
		
	});
	
	if(force){
		handleKeyPress();
	}
	
	
	async function handleKeyPress(e = false){
		/**** VARIABLE SET-UP AND FLAGS FOR LOGIC ****/
		console.log("Running KeyPress");
		
		if (e.keyCode == 13){
			e.preventDefault();
			return false;
		}
		
		console.log("Creating Variables");
		
		//need some key variables next
		let inputBarcode = $('#barcode-input-form-field').val();
		let boolScanner = false;
		let forceReset = false;
		let localDisplay = Array();
		let globalDisplay = Array();
		let hsDisplay = Array();
		if(typeof(window.smartLookUpState) == 'undefined'){
			window.smartLookUpState = false;
		}
		if(typeof(window.globalSearchStarted) == 'undefined'){
			window.globalSearchStarted = false;
		}
		
		//unset our window variables if we need to. This will let users delete data from the field and copy/paste new data in.
		if(inputBarcode.length < 3){
			window.smartLookUpState = false;
			
			if(typeof(window.localDataLookup) != 'undefined'){
				window.localDataLookup = undefined;
			}
			if(typeof(window.globalDataLookup) != 'undefined'){
				window.globalDataLookup = undefined;
			}
		}
		
		//We can get a good sense if this was a scanner or copy/paste which will have a few small systems added
		console.log(window.localDataLookup);
		console.log(typeof(window.localDataLookup));
		if(inputBarcode.length > 3 && typeof(window.localDataLookup) == 'undefined'){
			boolScanner = true;
		}
		
		//Some weird situations can arise that may require us to force a new query, so we'll create overrides here
		console.log(window.inputBarcodeLength);
		console.log(inputBarcode.length);
		if(
			(window.inputBarcodeLength > 3 && inputBarcode.length < window.inputBarcodeLength)
		){
			forceReset = true;
		}
		
		
		/**** END OF VARIABLE AND FLAGS ****/
		/**** "MODEL" SYSTEM AND RAW DATA ****/
		
		console.log('Input Length');
		console.log(inputBarcode.length);
		console.log('Is Scanner');
		console.log(boolScanner);
		console.log('Has Looked Up');
		console.log(window.smartLookUpState);
		console.log('Forced Reset');
		console.log(forceReset);
		
		//check if we need to get some raw data
		if(((inputBarcode.length == 3 || boolScanner) && !window.smartLookUpState) || forceReset){
			window.inputBarcodeLength = inputBarcode.length;
			window.smartLookUpState = true;
			
			//RUN OUR DISPLAY, we don't have data just yet, but let the user know it's coming!
			renderBarcodeList(localDisplay, globalDisplay, hsDisplay, inputBarcode)
			if(window.details.henry_schein_client){
				$('#hsData--output').html("Loading Results...");
			}
			$('#globalData--output').html("Loading Results...");
			$('#localData--output').html("Loading Results...");
			
			
			//get the IndexedDB Data
			window.localDataLookup = Array();
			window.localDataLookup = await indexedDataBySearch(inputBarcode);
			localDisplay = window.localDataLookup; //store it for display
			
			console.log('Raw Local Data');
			console.log(window.localDataLookup);
			
			//get the Global DB Data
			window.globalDataLookup = Array();
			window.globalDataLookup = await globalDataBySearch(inputBarcode);
			globalDisplay = window.globalDataLookup; //store it for display
			
			console.log('Raw Global Data');
			console.log(window.globalDataLookup);
			
			//in case someone clicks, we need some global data available
			window.clickLocal = localDisplay;
			window.clickGlobal = globalDisplay.global;
			window.clickHS = globalDisplay.hs;
			
			
			//we have our data set, but lots could have happened since it ran, so let's restart everything and kill the current execution
			console.log('Running Callback'); //make a note that we're starting new run.
			handleKeyPress(e); //start the run with the new data in place.
			return false; //kill the current process
		}
		
		/**** END "MODEL" ****/
		/**** "CONTROLLER" SYSTEM, FILTERING ****/
		
		//if we have local data, we may need to refine it for display. Run some filters.
		if(inputBarcode.length >= 3){
			localDisplay = filterLocalSmartData(inputBarcode);
			globalDisplay = filterGlobalSmartData(inputBarcode);
			if(window.details.henry_schein_client){
				hsDisplay = filterHSSmartData(inputBarcode);
			}
		}
		
		/**** END "CONTROLLER" SYSTEM ****/
		/**** VIEW SYSTEM ****/
		
		//we're going to run the view as it's own little function as there is a number of little logic checks in it that would be nice to keep seperate.
		
		//but first, do we think this came from a scanner?
		if(boolScanner && localDisplay.length == 1){
			//LOAD THE ITEM UP!
			//TO-DO
		}else{
			//RUN OUR DISPLAY!
			if(inputBarcode.length >= 3){
				renderBarcodeList(localDisplay, globalDisplay, hsDisplay, inputBarcode)
			}else{
				$('#smartLookUp').hide();
			}
		}
		
		/**** END VIEW SYSTEM ****/
	}
	
	
	
	/*****************
	*****************
	*** SUPPORTING METHODS
	*****************
	******************/
	
	
	// MODEL::Get raw data from IndexedDB
	async function indexedDataBySearch(searchQuery){
		let localItems = await SktDb.readAll('barcode');
		localItems = localItems.filter((item) => {
			return item.barcode.lastIndexOf(searchQuery, 0) === 0;
		});
		
		return localItems;
	}
	
	// MODEL::Get raw data from Global DB
	async function globalDataBySearch(searchQuery){
		//note the start of the process
		window.globalSearchStarted = true;
		
		//we can get the window data without passing it in.
		if(typeof(window.localDataLookup) != 'undefined'){
			let localData = window.localDataLookup;
		}else{
			let localData = Array();
		}
		let localDataBarcodes = Array();
		
		//a quick loop to build a simple array
		for (var i=0; i < localData.length; i++) {
			let barcodeString = "'"+localData[i].barcode+"'";
			localDataBarcodes.push(barcodeString);
		}
		
		//create our "NOT" string
		let localNotData = '';
		if(localDataBarcodes.length > 0){
			localNotData = "&local="+localDataBarcodes.join(',');
		}
		
		console.log("STARTING HS and GLOBAL DB search");
		console.log("search="+searchQuery+localNotData);
		
		let data = await fetch("/api/global-barcode-lookup", {
			method: "POST",
			headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/x-www-form-urlencoded'
			},
			body:"search="+searchQuery+localNotData
		}).then((e) => {
			window.globalSearchStarted = false;
			return e.json();
		});
		
		console.log("Raw Database");
		console.log(data);
		return data;
	}
	
	//CONTROLLER::Filter data
	function filterLocalSmartData(inputBarcode){
		let localDisplay = Array();
		if(typeof(window.localDataLookup) != 'undefined'){
			localDisplay = window.localDataLookup.filter((localItem) => {
				//if we don't have a properly formatted item, remove it
				if(typeof(localItem.barcode) == 'undefined'){
					return false;
				}
				
				//else, check if it includes our barcode string
				if(localItem.barcode.includes(inputBarcode)){
					return true;
				}else{
					return false;
				}
			});
		}
		window.clickLocal = localDisplay;
		return localDisplay;
	}
	
	function filterGlobalSmartData(inputBarcode){
		let globalDisplay = Array();
		if(typeof(window.globalDataLookup.global) != 'undefined'){
			globalDisplay = window.globalDataLookup.global.filter((globalItem) => {
				
				//if it's missing the HS manufacturer_item_number, it might be okay.
				if(typeof(globalItem.manufacturer_item_number) == 'undefined'){
					
					//check if it has a barcode
					if(typeof(globalItem.barcode) == 'undefined'){
						return false;
						
					//if it does, check the filter on that field only.
					}else{
						if(
							globalItem.barcode.includes(inputBarcode) 
						){
							return true;
						}else{
							return false;
						}
					}
				}else{
					//since we have a HS vendor number, check on both fields.
					if(
						globalItem.barcode.includes(inputBarcode) 
						|| globalItem.manufacturer_item_number.includes(inputBarcode)
					){
						return true;
					}else{
						return false;
					}
				}
			});
		}
		
		//update our global data in case someone clicks an item
		window.clickGlobal = globalDisplay;
		return globalDisplay;
	}
	
	
	function filterHSSmartData(inputBarcode){
		if(window.details.henry_schein_client){
			let hsDisplay = Array();
			if(typeof(window.globalDataLookup.hs) != 'undefined'){
				if(window.globalDataLookup.hs.length > 0){
					hsDisplay = window.globalDataLookup.hs.filter((globalItem) => {
						
						//since we have a HS vendor number, check on both fields.
						if(
							globalItem.barcode.includes(inputBarcode) 
							|| globalItem.manufacturer_item_number.includes(inputBarcode)
						){
							return true;
						}else{
							return false;
						}
						
					});
				}
			}
			
			//update our global data in case someone clicks an item
			window.clickHS = hsDisplay;
			return hsDisplay;
		}else{
			return false;
		}
	}
	
	
	//VIEW::Display UX and Options
	function renderBarcodeList(localDisplay, globalDisplay, hsDisplay, inputBarcode){
		let item_limit = 5; 
		console.log("Is Henry Schein: "+window.details.henry_schein_client);
		if(window.details.henry_schein_client){
			item_limit = 3;
		}
		
		if(inputBarcode.length >= 3){
			$('#smartLookUp').show();
		}else{
			$('#smartLookUp').hide();
		}
		let localContent = '';
		let globalContent = '';
		let hsContent = '';
		
		let webLookup = `<a id="upc-lookup-button" href="#" class="upc-lookup-button">Search the web</a>`;
		let manualEntry = `<a href='/#' class='close-lookup'>Enter your data manually</a>`;
		
		console.log("Final Local Data");
		console.log(localDisplay);
		
		console.log("Final Global Data");
		console.log(globalDisplay);
		
		//display Local
		if(localDisplay.length > 0){
			for (var i=0; i < localDisplay.length; i++) {
				if(i == item_limit){break;}
				localContent += `
					<div class='smart-option' data-id='${localDisplay[i].id}' data-key='${i}' data-local="true">
						${localDisplay[i].barcode} - ${localDisplay[i].product_name}
					</div>
					`;
			}
			
			$('#localData--output').html(localContent);
		}else{
			
			if((inputBarcode.length == 12 || inputBarcode.length == 13) && isValidUpcOrEan(inputBarcode) && globalDisplay.length == 0){
				localContent += `
					<div>
						This barcode does not match any known item in use for your SCAN IM account. ${manualEntry}, or ${webLookup}.
					</div>
					`;
				$('#localData--output').html(localContent);
			}else if((inputBarcode.length == 12 || inputBarcode.length == 13) && isValidUpcOrEan(inputBarcode) && globalDisplay.length > 0){
				localContent += `
					<div>
						This barcode does not match any known item in use for your SCAN IM account. ${manualEntry}.
					</div>
					`;
				$('#localData--output').html(localContent);
			}else{
				localContent += `
					<div>
						This barcode does not match any known item in use for your SCAN IM account and is not in a standard barcode format, ${manualEntry}.
					</div>
					`;
				$('#localData--output').html(localContent);
			}
			
		}
		
		//Display Global
		if(globalDisplay.length > 0){
			for (var i=0; i < globalDisplay.length; i++) {
				if(i == item_limit){break;}
				
				globalContent += `
						<div class='smart-option' data-key='${i}' data-local="false">
							${globalDisplay[i].barcode} - ${globalDisplay[i].product_name}
						</div>
						`;
				
			}
			
			$('#globalData--output').html(globalContent);
		}else{
			if(window.globalSearchStarted){
				$('#globalData--output').html("Loading Results...");
			}else{
				if((inputBarcode.length == 12 || inputBarcode.length == 13) && isValidUpcOrEan(inputBarcode)){
					globalContent += `
						<div>
							This barcode is not part of the SCAN IM system. ${manualEntry}, or ${webLookup}.
						</div>
						`;
					$('#globalData--output').html(globalContent);
				}else{
					globalContent += `
						<div>
							SCAN IM does not have a matching barcode and it does not match a valid barcode format, ${manualEntry}.
						</div>
						`;
					$('#globalData--output').html(globalContent);
				}
			}
		}
		
		if(hsDisplay.length > 0){
			for (var i=0; i < hsDisplay.length; i++) {
				if(i == item_limit){break;}
				hsContent += `
							<div class='smart-option' data-key='${i}' data-type="hs" data-local="false">
								${hsDisplay[i].barcode} (HS SKU) - ${hsDisplay[i].product_name} (Vendor #: ${hsDisplay[i].manufacturer_item_number})
							</div>
							`;
							
				$('#hsData--output').html(hsContent);
			}
		}else{
			hsContent = `
						<div>
							No matching items were found in our Henry Schein item list.
						</div>
						`;
			$('#hsData--output').html(hsContent);
		}
	}
	
	$('#smartLookUp').on('click', '.close-lookup', function(e){
		e.preventDefault();
		hideLookup();
	});
	
	$('#smartLookUp').on('click', '.smart-option', function(e){
		e.preventDefault();
		let key = $(this).data('key');
		let local = $(this).data('local');
		
		//we load data a little different based on where it came from
		if(local == 'true' || local == true){
			//console.log(window.clickLocal[key]);
			populateBarcodeInfo($(this).data('id'));
		}else if($(this).data('type') == 'hs'){
			//console.log(populateBarcodeInfo(window.clickGlobal[key], 'global'));
			populateBarcodeInfo(window.clickHS[key], 'global');
		}else{
			populateBarcodeInfo(window.clickGlobal[key], 'global');
		}
		
		//give the option to clear the system out
		$('#clear-base-item').show();
		
		//close our dropdown
		hideLookup()
	});
	
	
	//close our system down if we lose focus on it as well.
	$('body').on('click', function(e){
		if(
			$(e.target).parents('#barcode-input-form-field').length === 0
			&& e.target.id != 'barcode-input-form-field'
			&& $(e.target).parents('#smartLookUp').length === 0
			&& e.target.id != 'smartLookUp'
		){
			hideLookup();
		}
	});
	
	$('#barcode-input-form-field').on('keydown', async function(e){
		if (e.which == 9){
			hideLookup();
			return false;
		}
	});
	
	function hideLookup(){
		console.log('hiding the lookup');
		$('#smartLookUp').hide();
	}
}
