import SktDb from './sktDb';
import displayNotification from './displayNotification';

export default async function transferSubmit(e, url) {
  e.preventDefault();
  $('#main-loading-spinner').fadeIn();
  const formElement = $('#transfer-card')[0];
  const form_data = new FormData(formElement);
  const response = await fetch('/api/transfer', {
    method: 'POST',
    body: form_data,
  });
  const data = await response.json();
  if (data.message) {
    const notification = {
      status: data.status,
      message: data.message,
    };
    if (data.status === 'success') {
      sessionStorage.setItem('notification', JSON.stringify(notification));
    } else if (data.status === 'failed') {
      console.log('failed');
      displayNotification(notification);
      $('#main-loading-spinner').hide();
      return;
    }
  }
  let found_destination = false;
  const now = new Date();
  const secondsSinceEpoch = Math.round(now.getTime() / 1000);

  const room_inventory = await SktDb.getRecord(
    'room-inventory',
    form_data.get('current_room_inventory_id')
  );
  room_inventory.quantity =
    Number(room_inventory.quantity) -
    Number(form_data.get('transfer_quantity'));
  await SktDb.add(room_inventory, 'room-inventory');
  const all_room_inventories = await SktDb.readAll('room-inventory');
  all_room_inventories.forEach(async (single_room_inventory) => {
    if (
      single_room_inventory.deleted === '0' &&
      single_room_inventory.room_id === form_data.get('room_id') &&
      single_room_inventory.barcode_id === room_inventory.barcode_id
    ) {
      found_destination = true;
      if (single_room_inventory.id.toString(10).length > 9) {
        // Logic for failedpost edit in target room
        const data = await SktDb.getRecord(
          'failedposts',
          single_room_inventory.id
        );
        const old_form_data = JSON.parse(data.formdata);
        old_form_data.quantity =
          Number(old_form_data.quantity) +
          Number(form_data.get('transfer_quantity'));
        data.formdata = JSON.stringify(old_form_data);
        await SktDb.add(data, 'failedposts');
        // This flag will be checked the endpoint and not create the location room since the sync up will contain the new room.
        form_data.append('ignore_location', true);
      }
      single_room_inventory.quantity =
        Number(single_room_inventory.quantity) +
        Number(form_data.get('transfer_quantity'));
      await SktDb.add(single_room_inventory, 'room-inventory').then(
        (document.location = url)
      );
    }
  });
  console.log('data', data);
  if (!found_destination) {
    const mock_room_barcode_alert = {
      id:
        data.records === undefined
          ? secondsSinceEpoch
          : data.records.room_barcode_alert_id,
      room_id: form_data.get('room_id'),
      barcode_id: room_inventory.barcode_id,
      min_threshold: 1,
    };

    const mock_room_inventory = {
      id:
        data.records === undefined
          ? secondsSinceEpoch
          : data.records.room_inventory_id,
      room_id: form_data.get('room_id'),
      barcode_id: room_inventory.barcode_id,
      room_barcode_alert_id:
        data.records === undefined
          ? secondsSinceEpoch
          : data.records.room_barcode_alert_id,
      quantity: form_data.get('transfer_quantity'),
      update_time: new Date().toMysqlFormat(),
      deleted: '0',
    };

    await SktDb.add(mock_room_barcode_alert, 'room-barcode-alert');
    await SktDb.add(mock_room_inventory, 'room-inventory');
    document.location = url;
  }
}
