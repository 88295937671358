import BarcodeResultsManager from './BarcodeResultsManager'

export const LOW_ALERT_QUANTITY_MOD = 2
export const OVERSTOCK_QUANTITY_MOD = 0.85
export const ADD = 'add'
export const SUBTRACT = 'SUBTRACT'
export let itemQuantityAdjustment = ADD
export let isItemMenuExpanded = false
export const queryString = window.location.search
export const urlParams = new URLSearchParams(queryString)
export const results = []
export const results2 = new BarcodeResultsManager()
// export let online

// Adjust global variable functions
export function setItemQuantityAdjustment (str) {
  if (str == ADD || str == SUBTRACT) {
    itemQuantityAdjustment = str
  } else {
    console.log('ERROR: Par1 must equal ADD/SUBTRACT')
  }
}

export function setIsItemMenuExpanded (bool) {
  if (bool == true || bool == false) {
    isItemMenuExpanded = bool
  } else {
    console.log('ERROR: Par1 must equal Boolean')
    console.log(bool)
  }
}
/*
Alert Quantity mod is used to determine when an item is in yellow alert.
IF red alert is simply below the threshold, the LOW_ALERT_QUANTITY_MOD
multiplied by the threshold is the yellow threshhold.
EG: Threshold = 100, modifier is 2, so yellow threshold becomes 200.
*/
