export default async function roleHandler() {

    $(document).ready(async ()=>{
        let res = await fetch('/manage/users/get-roles')
        res = await res.json()
        for (let i in res) {
            let html = `
				<li>
					<span data-rolename="${res[i].id}">${res[i].name}</span><br/>
					<label class="switch">
						<input type="checkbox" class="role_checkboxes" id="${res[i].id}" />
						<span class="slider round"></span>
					</label>
				</li>`;
            $('#role_container').append(html)
        }
		
		//match the data
		newUserRole(2);
		
		$('.user-form').on('change', '#user-role', function(e){
			let role_id = $(this).val();
			newUserRole(role_id);
		});
		
		$('.main-container').on('keyup', '#user-filter', function(e){
			//set our search term
			let search = $(this).val().toLowerCase();;
			
			if(search != ''){
				//set some vars
				var numUsers = $('.user-row').length;
				let hiddenUsers = 0;
				
				//check each row for a matching search query
				$('.user-row').each(function(count){
					let first = $(this).children('.user-row-first-name').text().toLowerCase();;
					let last = $(this).children('.user-row-last-name').text().toLowerCase();;
					let email = $(this).children('.user-row-email').text().toLowerCase();;
					
					if(first.includes(search) || last.includes(search) || email.includes(search)){
						$(this).show();
					}else{
						$(this).hide();
						hiddenUsers++;
					}
				});
				
				//if we hid all of the users with the filter, show a notice message
				if(hiddenUsers == numUsers){
					$('#no-matching-users').show();
				}else{
					$('#no-matching-users').hide();
				}
			}else{
				$('.user-row').show(); //if we have an empty string, show everything
				$('#no-matching-users').hide(); //hide the notice
			}
		});
    })
	
	async function newUserRole(role_id){
		
		$('#user-form-role-permission').html("Loading...");
		
		let load_role_data = new FormData();
		load_role_data.append("role_id", role_id);
		
		let role_response = await fetch('/onlinerequest/load-role', {
			method: 'POST',
			body: load_role_data
		})
		
		role_response = await role_response.json();
		
		let permission_list = role_response.map(permission => {
			let name = permission.name;
			if(permission.active_permissions > 0){
				return `<li><img src="/resources/img/iconmonstr-check-mark-circle-filled-24.png" alt="Has Permission" />${name}</li>`;
			}else{
				return `<li><img src="/resources/img/iconmonstr-x-mark-circle-filled-24.png" alt="Does Not Have Permission" />${name}</li>`;
			}
		})
		
		$('#user-form-role-permission').html(permission_list);
	}

    $('#new-role-submit').on('click', async (e)=> {
        e.preventDefault()
        
		if(!window.permissions.manage_users){
			alert('You do not have the correct permissions for this');
			return;
		}
		

        let new_user = {roles: []}

        new_user.id = document.getElementById('id').value
        new_user.name = document.getElementById('name').value
        new_user.description = document.getElementById('description').value
        new_user.email_group = document.getElementById('email_group').value
		
        let roles = document.getElementsByClassName('role_checkboxes')
        roles = Array.from(roles)
        let new_user_data = new FormData()
        new_user_data.append("id", new_user.id)
        new_user_data.append("name", new_user.name)
        new_user_data.append("email_group", new_user.email_group)
        new_user_data.append("description", new_user.description)
		
        let user_response = await fetch('/users/add-role', {
            method: 'POST',
            body: new_user_data
        })

        user_response = await user_response.json()
        console.log(user_response)

        let last_id
        try {
			last_id = user_response
            
        } catch (error) {
            console.log(error)
            last_id = false
            // TODO - Add error handling
        }
		
        // Add role permissions for new user
		let new_role_data = new FormData()
		new_role_data.append("role_id", last_id)
        for (let i in roles) {
            let role = roles[i]
            if (role.checked) {
                new_role_data.append("permission_id["+i+"]", role.id)
            }           
        }
		console.log(new_role_data);
		let role_response = await fetch('/users/add-role-permissions', {
			method: 'POST',
			body: new_role_data
		})

		role_response = await role_response.json()
		console.log(role_response)

        window.location.reload(true)
    })
	
	$('body').on('click', '.btn-edit-role', async (e)=> {
		e.preventDefault();
		
		let role_id = e.currentTarget.dataset.role;
		
		let load_role_data = new FormData();
		load_role_data.append("role_id", role_id);
		
		let role_response = await fetch('/onlinerequest/load-edit-role', {
			method: 'POST',
			body: load_role_data
		})
		
		role_response = await role_response.json();
		console.log(role_response);
		
		$('#id').val(role_response.id);
		$('#name').val(role_response.name);
		$('#description').val(role_response.description);
		$('#email_group').val(role_response.email_group);
		$('#new-role-submit').val('Update Role'); 
		for (let i in role_response.permissions) {
            let permission = role_response.permissions[i];
			$('#'+permission).attr('checked','checked');
		}
		
		document.getElementById('focus-role').scrollIntoView();
		var roleSectionTitle = document.getElementById('focus-role').getElementsByTagName('H3');
		roleSectionTitle[0].innerHTML = "Editing existing role";
	});
	
	$('.btn-delete-role').on('click', async (e)=> {
		e.preventDefault();
		
		let role_id = e.currentTarget.dataset.role;
		
		let delete_role_data = new FormData();
		delete_role_data.append("role_id", role_id);
		
		let role_response = await fetch('/onlinerequest/delete-role', {
			method: 'POST',
			body: delete_role_data
		})
		
		role_response = await role_response.json();
		console.log(role_response);
		
		if(role_response.error == false){
			window.location.reload()
		}else{
			alert(role_response.message);
		}
	});
}