import countSKU from './countSKU';
import getLocationsAndRoomsForHTML from './getLocationsAndRoomsForHTML';

export default class ItemSort {
  constructor(items) {
    this.items = items;
  }

  getItems() {
    return this.items;
  }

  /*
  Using seperate filters and changing results to set so that there are no duplicates
  and result priority is expressed as name match > description match > barcode match
  */
  search(searchString) {
    let result = [];
    const productNameMatches = this.items.filter((word) =>
      word.barcode.product_name
        .toLowerCase()
        .includes(searchString.toLowerCase())
    );
    const productDescriptionMatches = this.items.filter((word) =>
      word.barcode.product_description
        .toLowerCase()
        .includes(searchString.toLowerCase())
    );
    const barcodeMatches = this.items.filter((word) =>
      word.barcode.barcode.includes(searchString)
    );
    result = result.concat(productNameMatches);
    result = result.concat(productDescriptionMatches);
    result = result.concat(barcodeMatches);
    result = [...new Set(result)];

    this.items = result;
    return this;
  }

  // This is used seperately from the filter system only in the alerts page
  filterAlerts(lowRangeMultiplier, highRangeMultiplier) {
    this.items = this.items.filter((item) => {
      
		if(Number(item.quantity) <= Number(item.room_barcode_alert.min_threshold)){
			return true;
		}
		
		/*
		if(Number(item.quantity) > Number(item.room_barcode_alert.max_threshold)
          item.room_barcode_alert.max_threshold > 0){
			return true;
		}*/
    });
    return this;
  }

  filterLocation(locationId) {
    if (locationId === 'all') return this;
    this.items = this.items.filter(
      (item) => Number(item.room.location_id) === Number(locationId)
    );
    return this;
  }

  filterArchived(bool) {
    //console.log(this.items);
    // console.log(bool)
    // If bool is true show live items, if false show archived items
    this.items = this.items.filter((item) => {
		//console.log(item); 
		return item.barcode.live == bool;
		}
	); 
    //console.log(this.items.length);
    // Adjust number of items

    countSKU();
    return this;
  }

  sortAlerts() {
    this.items = this.items.sort(function (a, b) {
      const aVal =
        Number(a.quantity) / Number(a.room_barcode_alert.min_threshold);
      const bVal =
        Number(b.quantity) / Number(b.room_barcode_alert.min_threshold);
      if (aVal < bVal) {
        return -1;
      }
      if (aVal > bVal) {
        return 1;
      }
      return 0;
    });
    return this;
  }

  sortMaxAlerts() {
    this.items = this.items.sort(function (a, b) {
      const aVal =
        Number(a.quantity) / Number(a.room_barcode_alert.max_threshold);
      const bVal =
        Number(b.quantity) / Number(b.room_barcode_alert.max_threshold);
      if (aVal < bVal) {
        return -1;
      }
      if (aVal > bVal) {
        return 1;
      }
      return 0;
    });
    return this;
  }

  sortRoom() {
    this.items = this.items.sort((a, b) => {
      const roomA = a.room.name.toUpperCase();
      const roomB = b.room.name.toUpperCase();
      if (roomA < roomB) {
        return -1;
      }
      if (roomA > roomB) {
        return 1;
      }
      return 0;
    });
    return this;
  }

  sortLocation() {
    this.items = this.items.sort((a, b) => {
      const roomA = a.location.name.toUpperCase();
      const roomB = b.location.name.toUpperCase();
      if (roomA < roomB) {
        return -1;
      }
      if (roomA > roomB) {
        return 1;
      }
      return 0;
    });
    return this;
  }

  sortItemName() {
    this.items = this.items.sort(function (a, b) {
      const nameA = a.barcode.product_name.toUpperCase();
      const nameB = b.barcode.product_name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    return this;
  }

  sortBarcode() {
    this.items = this.items.sort(function (a, b) {
      const nameA = a.barcode.barcode.toUpperCase();
      const nameB = b.barcode.barcode.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    return this;
  }

  sortLastUpdated() {
    this.items = this.items
      .sort((a, b) => new Date(a.update_time) - new Date(b.update_time))
      .reverse();
    return this;
  }

  sortItemQuantity() {
    this.items = this.items.sort(
      (a, b) => Number(a.quantity) - Number(b.quantity)
    );
    return this;
  }

  reverse() {
    this.items = this.items.reverse();
    return this;
  }

  sort(searchType) {
    switch (searchType) {
      case 'item':
        this.sortItemName();
        break;
      case 'barcode':
        this.sortBarcode();
        break;
      case 'quantity':
        this.sortItemQuantity();
        break;
      case 'alert':
        this.sortAlerts();
        break;
      case 'maxalert':
        this.sortAlerts();
        break;
      case 'room':
        this.sortRoom();
        break;
      case 'location':
        this.sortLocation();
        break;
      default:
        return this;
    }
    return this;
  }

  filter(filterName, lowRangeMultiplier, highRangeMultiplier) {
    switch (filterName) {
      case 'green':
        this.items = this.items.filter((item) => {
          return (
            Number(item.quantity) >=
              Number(item.room_barcode_alert.min_threshold) *
                lowRangeMultiplier &&
            (item.room_barcode_alert.max_threshold === null ||
              Number(item.quantity) <=
                Number(item.room_barcode_alert.max_threshold) *
                  highRangeMultiplier)
          );
        });
        break;
      case 'yellow':
        this.items = this.items.filter((item) => {
          return (
            (Number(item.quantity) >=
              Number(item.room_barcode_alert.min_threshold) &&
              Number(item.quantity) <
                Number(item.room_barcode_alert.min_threshold) *
                  lowRangeMultiplier) ||
            (Number(item.quantity) <=
              Number(item.room_barcode_alert.max_threshold) &&
              Number(item.quantity) >
                Number(item.room_barcode_alert.max_threshold) *
                  highRangeMultiplier)
          );
        });
        break;
      case 'red':
        this.items = this.items.filter((item) => {
          return (
            Number(item.quantity) <
              Number(item.room_barcode_alert.min_threshold) ||
            (item.room_barcode_alert.max_threshold !== null &&
              Number(item.quantity) >
                Number(item.room_barcode_alert.max_threshold))
          );
        });
        break;
      default:
        return this;
    }
    return this;
  }

	filterLow(filterName) {
		
		switch (filterName) {
			case 'green-plus':
				let upper_limit = item.room_barcode_alert.max_threshold || Infinity;
				
				return (
						Number(item.quantity) > Number(upper_limit)
					);
			break;
			case 'green':
				this.items = this.items.filter((item) => {
					let upper_limit = item.room_barcode_alert.max_threshold || Infinity;
					let lower_limit = item.room_barcode_alert.min_threshold;
					let midpoint = Number(((Number(upper_limit)-Number(lower_limit))/2))+Number(lower_limit);
					
					return (
						Number(item.quantity) >= Number(midpoint) &&
						Number(item.quantity) <= Number(upper_limit)
					);
				});
			break;
			case 'yellow':
				this.items = this.items.filter((item) => {
					let upper_limit = item.room_barcode_alert.max_threshold || Infinity;
					let lower_limit = item.room_barcode_alert.min_threshold;
					let midpoint = Number(((Number(upper_limit)-Number(lower_limit))/2))+Number(lower_limit);
					
					return (
						Number(item.quantity) >= Number(lower_limit) &&
						Number(item.quantity) < Number(midpoint)
					);
				});
			break;
			case 'red':
				this.items = this.items.filter((item) => {
					return (
						Number(item.quantity) <= Number(item.room_barcode_alert.min_threshold)
					);
				});
			break;
			default:
			return this;
		}
		return this;
	}

  filterRoom(roomid) {
    if (roomid === 'all') {
      return this;
    }

    this.items = this.items.filter((room) => {
      return Number(room.room_id) === Number(roomid);
    });

    return this;
  }
}
