export default class ItemsIndex {
  constructor (activeLocation) {
    // set index mode, (normal, pull, restock)
    this.urlParams = new URLSearchParams(window.location.search)
    this.mode = this.urlParams.get('mode')

    // Get room/location filter settings
    try {
      this.active_location_or_room_id = activeLocation.id
      this.active_type = activeLocation.type
    } catch (e) {
      this.active_type = 'all'
    }
  }

  test () {
    $('#barcode-item-container').on('click', '#previous-barcode', () => {
      console.log('test')
    })
  }

  // registerEvents () {

  // }

  // nextBarcodeItem () {

  // }
}
