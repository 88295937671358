import ItemSort from './itemSort'
import SktDb from './sktDb'
import getCookie from './getCookie'



import getNotificationColor from './getNotificationColor'

// globals
import './globals'
import { LOW_ALERT_QUANTITY_MOD, OVERSTOCK_QUANTITY_MOD } from './globals'

export default async function prepareAlertsIndexPage () {
	sessionStorage.setItem('itemBackButton', 'alerts');
	
  $('#items-index .item-container').addClass('columned-items')
  const AlertItem = ({
    barcode, room_barcode_alert, quantity, id, room
  }) => {
    let notificationColor = getNotificationColor(quantity, room_barcode_alert.min_threshold, room_barcode_alert.max_threshold)
	
    // Notice alert type sets to - even if the room is also overstocked (Yes it can be both, should change this later) this is because its more important that the user knows if its low.
	
	let alertTypeIndicator = '';	
	let notificationIcon = notificationColor;	
	let showNotice = true;
	if(notificationColor == 'none'){
		showNotice = false;
	}
	if(notificationColor == 'green-plus'){
		alertTypeIndicator = '+';
		notificationColor = 'green';
	}
	if(notificationColor == 'red'){
		alertTypeIndicator = '-';
	}
	
    const barcodeSku = getCookie('show_barcode_sku') === 'true' ? `<p class="barcode">${barcode.barcode.length > 0 ? barcode.barcode : 'No Barcode'}</p>` : ''
    return `
      <a class="item-card" href="/edit-item?id=${id}">
          <div class="item-image" ${barcode.image_url ? "style='background:url(\"" + barcode.image_url + "\") no-repeat center;background-size:cover;'" : ''}>
          </div>
          <div class="item-details">
              <p class="room">${room !== undefined ? room.name : 'Deleted Room'}</p>
              <p class="name">${barcode.product_name || 'Undefined'}</p>
              ${barcodeSku}
              <p class="quantity">Qty: ${quantity} ${barcode.uom_primary_unit_type.toUpperCase()} | Min: ${room_barcode_alert.min_threshold} | Max: ${room_barcode_alert.max_threshold || 'N/A'}</span></p>
          </div>
          <div class="stock-indicator ${showNotice == false ? 'hideNotice' : ''}">
              <div class="alert-type-indicator text-${notificationColor}">
				${alertTypeIndicator}
              </div>
              <img src="/resources/img/${notificationIcon}_notification.png" />
          </div>
      </a> 
    `
  }

  const active_location_or_room_id = JSON.parse(getCookie('active_location')).id
  const active_type = JSON.parse(getCookie('active_location')).type
  let items = await SktDb.readAll('room-inventory')
  items = items.filter((item) => {
    return item.deleted === '0'
  })

  await Promise.all(items.map(async (item) => {
    const barcode = await SktDb.getRecord('barcode', item.barcode_id)
    const room = await SktDb.getRecord('room', item.room_id)
    const room_barcode_alert = await SktDb.getRecord('room-barcode-alert', item.room_barcode_alert_id)
    item.barcode = barcode
    item.room = room
    item.room_barcode_alert = room_barcode_alert
  }))

  if (active_type === 'location') {
    items = new ItemSort(items).filterLocation(active_location_or_room_id)
  } else if (active_type === 'room') {
    items = new ItemSort(items).filterRoom(active_location_or_room_id)
  } else {
    items = new ItemSort(items).filterLocation('all')
  }

  // Filter out archived items
  items = items.items

  items = new ItemSort(items).filterArchived(1)

  const alertItems = items.filterAlerts(LOW_ALERT_QUANTITY_MOD, OVERSTOCK_QUANTITY_MOD).sortAlerts().getItems()

  $('#items-index .item-container').html(alertItems.map(AlertItem).join(''))
}
