import populateBarcodeInfo from './populateBarcodeInfo';
import mainSearchExecute from './mainSearchExecute';
import { isValidBarcode, apiLookup } from './upcAPI';
import { data } from 'jquery';

export default async function autocomplete(inp, arr) { 
	//console.log('Running Autocomplete');
	window.singleItem = '';
	
    if (inp === null) {
        return;
    }
    let currentFocus;
    // We can 'pluck' out an array of just current barcodes in the local [barcode] table.
    // We do this so we can quickly cross-reference against all known "user/local" barcodes when deciding below whether to display HS barcodes.
    var curBarcodeList = pluck(arr,'barcode');
  
    inp.addEventListener('input', async function (e) {
		window.singleItem = '';
        //timeout(1);
        // import database  ?? Not sure why this was already in here?[brk]
        const val = this.value;
        closeAllLists();
        if (!val) {
          return false;
        }

        currentFocus = -1;
        const a = document.createElement('DIV');
        a.setAttribute('id', this.id + 'autocomplete-list');
        a.setAttribute('class', 'autocomplete-items');
        this.parentNode.appendChild(a);
        for (let i = 0; i < arr.length; i++) {
          if (
            arr[i].product_name != null &&
            (arr[i].product_name.toUpperCase().includes(val.toUpperCase()) ||
              arr[i].barcode.toUpperCase().includes(val.toUpperCase())) &&
            a.childElementCount < 5
          ) {
            const b = document.createElement('DIV');
            if (inp.id === 'barcode-input-form-field') {
              b.innerHTML += arr[i].barcode + ' - ' + arr[i].product_name;
            } else {
              b.innerHTML += arr[i].product_name + ' - ' + arr[i].barcode;
            }
            b.innerHTML += "<input type='hidden' value='" + arr[i].id + "'>";
            b.innerHTML +=
              "<input type='hidden' value='" + arr[i].product_name + "'>";
            b.addEventListener('click', function (e) {
              if (inp.className === 'main-search') {
                $('.main-search').val(this.getElementsByTagName('input')[1].value);
                mainSearchExecute();
              } else {
                  // IF we have a new item not in the indexDB (this has been designed to only assume items exist there - ugh!), we need to be able to account for this
                  // Perhaps we need to now automatically add it in. I believe we can assume that since we are getting online data from global DB, that we can write it 
                  // automatically to online client DB, or do we just add it to local indexDB, and hope(?) it gets synced elsewhere/later.
                  // 
                  // populateBarcodeInfo() will fail as it needs to GET information first, and the new HS information (coming from the server), won't be in indexDB.
                  // I think we have an IF logic here, to decide whether to send in HS data or not.
                //console.log(arr[i]);
				if(inp.id == 'product-name'){
					window.singleItem = this.getElementsByTagName('input')[0].value;
					return false;
				}else{
					populateBarcodeInfo(this.getElementsByTagName('input')[0].value); // ORIGINAL MAY STILL USE IT SO DONT DELETE
					//populateBarcodeInfo(arr[i]);
					$('#clear-base-item').show();
				}
              }
              closeAllLists();
            });
            a.appendChild(b);
          }
        };

        // If Creating a New Item, we also want to return results from the SKT Global database [barcode] and [barcode_henry_schein] tables.
        // We wait until the user has entered at least 3 characters of a SKU before sending that string to perform a database query.
        if (this.value.length > 2 && inp.id === 'barcode-input-form-field' && a.childElementCount < 4)
        {
            //console.log("STARTING HS and/or GLOBAL DB search");
            let data = await fetch("/api/global-barcode-lookup", {
                method: "POST",
                headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded'
                },
                //body:new URLSearchParams(data).toString()
                body:"search="+this.value
            }).then((e) => {
              return e.json();
            });
            //console.log(data);
            var hsSkuData = data;
            
            //console.log("done with HS search");
            
            // NOTE:  When getting values this way, we aren't pulling from indexDB, therefore the items don't have ID's from local [barcodes] already.
            // If you select an item from the HS data list, it currently does NOT populate Item Name or Description fields with any data.
        }
        // SECTION BELOW IS FOR ADDING IN Henry Schein data from global DB. We'll probably need another one to search against [barcode] global DB as well.


        //console.log("here");
        //console.log(a);

        

        if (typeof hsSkuData != "undefined") {
            for (let i = 0; i < hsSkuData.length; i++) {
                //console.log(hsSkuData[i].barcode);
                
                //console.log(curBarcodeList);
                //console.log(!curBarcodeList.includes(hsSkuData[i].barcode));
                // !curBarcodeList.includes(hsSkuData[i].barcode) &&
                //console.log(hsSkuData[i]);
              if (
                hsSkuData[i].product_name != null && !curBarcodeList.includes(hsSkuData[i].barcode) &&
                (hsSkuData[i].product_name.toUpperCase().includes(val.toUpperCase()) ||
                  hsSkuData[i].barcode.toUpperCase().includes(val.toUpperCase()) || 
                  hsSkuData[i].manufacturer_item_number.toUpperCase().includes(val.toUpperCase())) &&
                a.childElementCount < 5
              ) {
                  //console.log("am I here damit");
                const b = document.createElement('DIV');
                if (inp.id === 'barcode-input-form-field') {
                    if (hsSkuData[i].hs_global_barcode_id) {
                        b.innerHTML += hsSkuData[i].barcode + ' (HS SKU) - ' + hsSkuData[i].product_name + ' (Vendor SKU: ' + hsSkuData[i].manufacturer_item_number + ')';
                    } else {
                        b.innerHTML += hsSkuData[i].barcode + ' - ' + hsSkuData[i].product_name;
                    }
                  
                  b.innerHTML += "<input type='hidden' value='" + hsSkuData[i].id + "'>";
                  b.innerHTML += "<input type='hidden' value='" + hsSkuData[i].product_name + "'>";
                }
                
                b.addEventListener('click', function (e) {

                      // IF we have a new item not in the indexDB (this has been designed to only assume items exist there - ugh!), we need to be able to account for this
                      // Perhaps we need to now automatically add it in. I believe we can assume that since we are getting online data from global DB, that we can write it 
                      // automatically to online client DB, or do we just add it to local indexDB, and hope(?) it gets synced elsewhere/later.
                      // 
                      // populateBarcodeInfo() will fail as it needs to GET information first, and the new HS information (coming from the server), won't be in indexDB.
                      // I think we have an IF logic here, to decide whether to send in HS data or not.
                    //console.log(hsSkuData[i]);
                    //populateBarcodeInfo(this.getElementsByTagName('input')[0].value);
                    populateBarcodeInfo(hsSkuData[i], 'global');
                    $('#clear-base-item').show();
                  
                  closeAllLists();
                });
                a.appendChild(b);
              }
            };
        }

    });

    // currentFocus = 1;
    // Main Search Bar Input
    inp.addEventListener('keyup', function (e) {

        let x = document.getElementById(this.id + 'autocomplete-list');
        let list = $('.autocomplete-items');

        // console.log(currentFocus);

        // currentFocus--;
        // addActive(x);

        // let results = x.childNodes.length
        if (x) {
          x = x.getElementsByTagName('div');
        }

        //console.log(x);
        //console.log(typeof(x));

        if (x != null) {
            if (x.length == 1) {
              let sku = x[0].innerText.split(' ').pop();
              let skuLeft = x[0].innerText.replace(/ .*/, '');
              //console.log(skuLeft);
              if (sku == inp.value || skuLeft == inp.value) {
                currentFocus--;
                addActive(x);
                x[currentFocus].click();
              }
            }
        }

        // console.log(x)
        if (e.keyCode === 40) {
          currentFocus++;
          addActive(x);
        } else if (e.keyCode === 38) {
          // up
          currentFocus--;
          addActive(x);
        } else if (e.keyCode === 13) {
          e.preventDefault();
		  
          if (currentFocus > -1) {
            if (x) {
              x[currentFocus].click();
            }
          }
		  
		 /* if($(e.target).is('#product-name')){
				console.log('hello world');
				let barcode = $('#barcode-input-form-field').val();
				let activeData = `barcode-input=${barcode}`;
				apiLookup(activeData);
		  }else{
				checkForMatches();
		  }*/

        }
        // console.log('check for matches time')
    });

    // $(document).ready(()=> {
    //   try {
    //     checkForMatches()

    //   } catch (error) {
    //     console.log('didnt work')
    //   }
    // })

    function checkForMatches() {
        let list = $('.autocomplete-items');
        let input = $('.main-search');
        let mobileInput = $('#mobile-autocomplete-search');
        let mobileList = $('mobile-autocomplete-searchautocomplete-list');
        // console.log(input)
        if (isValidBarcode(input.val()) && list.children().length == 0) {
            //console.log('Valid Barcode');
            list.append('<div>Searching for item...</div>');

            let activeData = `barcode-input=${input.val()}`;
            apiLookup(activeData);
            return;
        }

        if (isValidBarcode(mobileInput.val()) && mobileList.children().length == 0)
        {
            //console.log('Valid Barcode');
            list.append('<div>Searching for item...</div>');

            let activeData = `barcode-input=${mobileInput.val()}`;
            apiLookup(activeData);
            return;
        }
    }

    function addActive(x) {
        if (!x) {
            return false;
        }
        removeActive(x);
        if (currentFocus >= x.length) {
            currentFocus = 0;
        }
        if (currentFocus < 0) {
            currentFocus = x.length - 1;
        }
        x[currentFocus].classList.add('autocomplete-active');
    }

    function removeActive(x) {
        for (let i = 0; i < x.length; i++) {
            x[i].classList.remove('autocomplete-active');
        }
    }

    function pluck(arr, property) {
        return $.map(arr, function (item) {
            return item[property].trim();
        });
    }
    
    function timeout(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    function closeAllLists(elmnt) {
        const x = document.getElementsByClassName('autocomplete-items');
        for (let i = 0; i < x.length; i++) {
            if (elmnt !== x[i] && elmnt !== inp) {
            x[i].parentNode.removeChild(x[i]);
            }
        }
    }
	
	
	inp.addEventListener('keydown', async function (e) {
		//if we're focusing elsewhere with an actively loaded item, set it.
		if (e.which == 9){
			if(window.singleItem != ''){
				populateBarcodeInfo(window.singleItem);
				$('#clear-base-item').show();
			}
		}
	});
    
    document.addEventListener('click', function (e) {
		//if we're focusing elsewhere with an actively loaded item, set it.
		if(!$(e.target).hasClass('autocomplete-active')){
			if(window.singleItem != ''){
				populateBarcodeInfo(window.singleItem);
				$('#clear-base-item').show();
			}
		}
			
        closeAllLists(e.target);
    });
}
