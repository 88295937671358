export default function JSON2CSV (objArray) {
  const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray

  let str = ''
  var line = ''

  for (let i = 0; i < array.length; i++) {
    var line = ''

	for (var index in array[i]) {
		line += array[i][index] + ','
	}

    line = line.slice(0, -1)
    str += line + '\r\n'
  }
  return str
}
