import SktDb from './sktDb'

export default async function populateBarcodeInfo(id, source = 'local')
{
    // TODO:  autocomplete.js passes in the first input field which, if selected from data already in indexDB, will have an ID. However, new HS (or other global sku data) data
    // will not have an existing id. Instead in the field it will have 'undefined'. So...I think we can pass in the array data we get back, logic gate to see if ID is undefined,
    // and then skip the getRecord() function and just populate the field with what we already got from the online server.
	
	//set-up our barcode object with our data
	if(source === 'global'){
		console.log('global db entry');
		var barcode = id; //technically, we're sending a full barcode object from H.S.
		barcode.id = 0; //ID's with 0 trigger the 'adding it to the local system'
		
	}else{
        console.log("here I hope");
		var barcode = await SktDb.getRecord('barcode', id); //otherwise, get the barcode from the local DB
	}
	//console.log(id);
	//populate out the whole item form with what we've got.
	$("input[name='product_name']").val(barcode.product_name)
	$("input[name='barcode']").val(barcode.barcode)
	$("input[name='uom_primary_unit_qty']").val(barcode.uom_primary_unit_qty)
	$("input[name='uom_secondary_unit_qty']").val(barcode.uom_secondary_unit_qty)
	$("input[name='uom_primary_unit_type']").val(barcode.uom_primary_unit_type)
	if(barcode.uom_secondary_unit_type != ''){
		$("input[name='uom_secondary_unit_type']").val(barcode.uom_secondary_unit_type)
	}
	$("input[name='product_description']").val(barcode.product_description)
	$("input[name='barcode_autofill_id']").val(barcode.id)
	$('.input-icons .open-barcode-scanner').hide()
	
    
	//if we've got an image, we want to upload it for later and show it now.
	let imgCheck = await fetch("/api/remote-image-check", {
		method: "POST",
		headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/x-www-form-urlencoded'
		},
		body:"search="+barcode.image_url
	}).then((e) => {
		return e.json();
	});
	
	//see if we found an image and display it if so
	if (imgCheck == 404 || imgCheck == 0) {
		console.log('IMAGE LOOKUP: IMAGE NOT FOUND');

	} else {
		console.log("IMAGE LOOKUP: IMAGE FOUND");

		$('#text-image-url-save').val(barcode.image_url).trigger('change');
		let url = `url('${barcode.image_url}') no-repeat center`;
		$('.item-image').css('background', url);

		$('#item-image-input').get(0).type = "text";
		$('#item-image-input').val = barcode.image_url;
	}
    
	//lock some of the input in the event we have this item in our local database
	if(source !== 'global'){
		$("input[name='barcode'], input[name='product_description'], input[name='product_name']").addClass('locked-input')
		$("input[name='barcode'], input[name='product_description'], input[name='product_name']").prop('readonly', true)
	}
}
