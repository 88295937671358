import getCookie from "./getCookie";

export default function convertTimeToTimezone(time) {
	/*** 
		Currently implemented on: /reports/chronological-history-report
		Notes:
			TimeZone impacts the date range being searched. Currently, I believe we 
			have everything stored in the database on Mountain time (at least, we've got a global
			setting for that in config.php).
			
			Everything appears to be working correctly and smoothly, but these few hours of sway from
			incorporating timezones could heavily impact how searches and saving data ends up working.
			
			Do we switch the database to run on UTC? Run every query through a timezone conversion first?
			How often is this being used right now? How high of a priority?
			
			Currently, this works to take the creation_time output from the database and convert it 
			for display for users. We're thinking through exactly how far to take this and will
			revisit it in the coming days.
	***/
	
	//test data
	//time = new Date(Date.UTC(2022, 4, 4, 12, 0, 0));
	//time = "2022-04-04T12:00:00Z";
	let formattedTime = (typeof time === "string" ? new Date(time) : time);
	
	console.log('Input Time')
	console.log(formattedTime);

	//load in our settings
	let settings = JSON.parse(getCookie("usersettings"));
	
	//get the current timezone in the settings
	let settingsTimeZone = settings.timezone;
	
	//convert the timezone to a full en-us string
	let tzString = '';
	
	console.log('Convert to Timezone');
	console.log(settingsTimeZone);
	
	//support the timezone options for various key cities
	switch(settingsTimeZone){
		case 'edt':
			tzString = 'America/New_York';
			break;
		case 'cdt':
			tzString = 'America/Chicago';
			break;
		case 'mdt':
			tzString = 'America/Denver';
			break;
		case 'mst':
			tzString = 'America/Phoenix';
			break;
		case 'pdt':
			tzString = 'America/Los_Angeles';
			break;
		case 'adt':
			tzString = 'America/Anchorage';
			break;
		case 'hst':
			tzString = 'Pacific/Honolulu';
			break;
	}
	
	//console.log(tzString);

	let options = {};
	options.timeZone = tzString;
	options.timeZoneName = 'short';
	
	//convert the time we passed in, into the new timezone using the toLocaleString js function.
	//https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleString
	const convertedTime = formattedTime.toLocaleString("en-US", options); 
	
	console.log('Output time');
	console.log(convertedTime);
	return convertedTime;
}
