import ItemSort from './itemSort';
import SktDb from './sktDb';
import getCookie from './getCookie';
import findGetParameter from './findGetParameter';
import addcsvquotes from './addcsvquotes';

// globals
import { LOW_ALERT_QUANTITY_MOD } from './globals';

export default async function prepareInventoryReportPage() {
  const ReportItem = ({
    barcode,
    room_barcode_alert,
    quantity,
    id,
    room,
    location,
  }) => {
    // set-up an array with this data for use in the CSV download
    const tempItem = [
      `"${addcsvquotes(barcode.barcode) || 'Undefined'}"`,
      `"${addcsvquotes(barcode.product_name) || 'Undefined'}"`,
      `"${addcsvquotes(location.name) || 'Undefined'}"`,
      `"${addcsvquotes(room.name) || 'Undefined'}"`,
      `"${quantity}"`,
      `"${barcode.uom_primary_unit_type}"`,
      `"${room_barcode_alert.min_threshold}"`,
      `"${room_barcode_alert.max_threshold || 'N/A'}"`,
    ];
    window.reportItems.push(tempItem);

    return `
			<tr>
				<td style="width: 110px;">
					<span class="nohide">${barcode.barcode || 'Undefined'}</span>
					<span class="hide" hidden><svg id="barcode${barcode.barcode}" class="barcode_svg"></svg></span>
				</td>
				<td style="width: 80px;">
					${barcode.product_name || 'Undefined'}
				</td>
				<td style="width: 80px;">
					${location.name || 'Undefined'}
				</td>
				<td style="width: 80px;">
					${room.name || 'Undefined'}
				</td>
				<td style="width: 20px;">
					${quantity}
				</td>
				<td style="width: 20px;">
					${barcode.uom_primary_unit_type.toUpperCase()}
				</td>
				<td style="width: 20px;">
					${room_barcode_alert.min_threshold}
				</td>
				<td style="width: 20px;">
					${room_barcode_alert.max_threshold || 'N/A'}
				</td>
			</tr>
		`;
  };

/*
<td class="hide" hidden>
<svg id="barcode${barcode.barcode}" class="barcode_svg"></svg>
</td>
*/

  const active_location_or_room_id = JSON.parse(getCookie('active_location'))
    .id;
  const active_type = JSON.parse(getCookie('active_location')).type;
  window.reportItems = [
    [
      'Barcode',
      'Product Name',
      'Location',
      'Room Name',
      'Quantity',
      'Min Threshold',
      'Max Threshold',
    ],
  ];

  let items = await SktDb.readAll('room-inventory');
  items = items.filter((item) => {
    return item.deleted === '0';
  });

  await Promise.all(
    items.map(async (item) => {
      const barcode = await SktDb.getRecord('barcode', item.barcode_id);
      const room = await SktDb.getRecord('room', item.room_id);
      const location = await SktDb.getRecord('location', room.location_id);
      const room_barcode_alert = await SktDb.getRecord(
        'room-barcode-alert',
        item.room_barcode_alert_id
      );
      item.barcode = barcode;
      item.room = room;
      item.room_barcode_alert = room_barcode_alert;
      item.location = location;
    })
  );

  // we want to respect the rooms and locations
  if (active_type === 'location') {
    items = new ItemSort(items).filterLocation(active_location_or_room_id);
  } else if (active_type === 'room') {
    items = new ItemSort(items).filterRoom(active_location_or_room_id);
  } else {
    items = new ItemSort(items).filterLocation('all');
  }

  // Filter out archived items
  items = items.items;

  items = new ItemSort(items).filterArchived(true);

  let reportItems = '';

  switch (findGetParameter('type')) {
    case 'low':
      items = items
        .filterLow('red')
        .sortAlerts()
        .getItems();
      break;
    default:
      items = items.sortAlerts().getItems();
  }

  //console.log(items);
  items = new ItemSort(items);
  switch (findGetParameter('sort')) {
    case 'barcode':
      reportItems = items.sort('barcode').getItems();
      break;
      reportItems = items.sort('item').getItems();
      break;
    case 'location':
      reportItems = items.sort('location').getItems();
      break;
    case 'room':
      reportItems = items.sort('room').getItems();
      break;
    case 'quantity':
      reportItems = items.sort('quantity').getItems();
      break;
    case 'min_threshold':
      reportItems = items.sort('alert').getItems();
      break;
    case 'max_threshold':
      reportItems = items.sort('maxalert').getItems();
      break;
    default:
      reportItems = items.sort('barcode').getItems();
  }

  //console.log(reportItems);

  // Handles up/down arrows assignment
  let localColumn = '#' + findGetParameter('sort');
  if (localColumn == '#location') {
    localColumn = localColumn + '1';
  }

  // Handles reset
  if (window.localStorage.getItem('lastColumn') !== localColumn) {
    window.localStorage.setItem('lastColumn', localColumn);
    window.localStorage.setItem('descending', 'false');
  }

  // Handles switching of ascending/descending, adds in up/down arrows
  if (window.localStorage.getItem('descending') == 'true') {
    reportItems.reverse();
    $(localColumn).append('<span>&#8593</span>');
    window.localStorage.setItem('descending', 'false');
  } else {
    window.localStorage.setItem('descending', 'true');
    $(localColumn).append('<span>&#8595</span>');
  }

  // DEBUG EXCEPTIONL Handles Location
  // if(findGetParameter('sort') == 'location') {
  //   console.log('Time to append')
  //   if (window.localStorage.getItem('descending') == 'true') {
  //     $('#location1').append("<span>&#8593</span>")
  //   } else {
  //     $('#location1').append('<span>&#8595</span>')
  //   }
  // }
  // Push new inventory order to dom
  
  $('#inventory-report-body').html(reportItems.map(ReportItem).join(''));
  console.log('Push to DOM complete');
}
