export async function loadCamera(){
	console.log('Loading Scanner');
	let html5QrCode = new Html5Qrcode("reader");
	$('#barcode-scanned').val('');
	$('#reader').html('');
	$('#scanner-instructions').html('Point your camera at the barcode');
	$('#barcode-scanner').removeClass('scanner-active');
	document.getElementById('quickCameraOptions').innerHTML = '';
	
	
	//we're going to store devices in case we need to restart the system, we can display them again
	if(typeof(window.knownDevices) != 'undefined'){
		displayCameras(window.knownDevices);
	}else{
		//check which cameras we have available
		await Html5Qrcode.getCameras().then(devices => {
			console.log('Loading Device Options');
			window.knownDevices = devices;
			displayCameras(devices);
		}).catch(err => {
		  // handle err
		});
	}
	
	console.log('If Camera ID, Loading Now');
	if(typeof(window.cameraFavoriteId) != 'undefined'){
	
		console.log('Running Camera Start on ID: '+window.cameraFavoriteId);
		let barcodeArray = Array();
		$('#barcode-scanner').addClass('scanner-active');
		
		html5QrCode.start(
			window.cameraFavoriteId, 
			{
				fps: 100,    // Optional, frame per seconds for qr code scanning
				aspectRatio: 1,
				qrbox: { width: 300, height: 300 }  // Optional, if you want bounded box UI
			},
			(decodedText, decodedResult) => {
				//handle success
				console.log("Scanned Item #: "+decodedText); //write to console
				
				//add the new barcode to the array
				barcodeArray.push(decodedText);
				console.log(barcodeArray); 
				
				if(barcodeArray.length == 6){
				//if we have 6 results, handle the success process
					console.log('Running the Barcode Scanner Success System');
					$('#scanner-instructions').html('<img src="/resources/img/checkmark-24px.svg" alt="success" /></span><br/><br/>Barcode Scanned<br/><br/><button style="display:inline-block;width:100px;" class="red-button" id="scanner-restart">Restart</button>');
					$('#barcode-scanned').val(decodedText); //write to html
					
				}else if(barcodeArray.length < 6){
				//if we have less than 6, handle the display process
					console.log('Barcode: '+decodedText+' added to array');
					
					//update our instructions
					let countdown = 4 - Math.round(barcodeArray.length / 2);
					$('#scanner-instructions').html('<span>'+countdown+'<span>');
				
				}else if(barcodeArray.length > 6){
				//if we have more than 6, we can ignore things (but keep logging)
					console.log('Barcode: '+decodedText+' but we already have a 6 results');
				}
			},
			(errorMessage) => {
				// parse error, ignore it.
			})
		.catch((err) => {
			// Start failed, handle it.
			console.log("Major Error:"); //write to console
			console.log(err); //write to console
		});
	}
}

function displayCameras(devices){
	let cameraOptions = '<h2>A default camera could not be identified. Please select one.</h2>';
	if (devices && devices.length) {
		console.log('Loading Camera Options');
		
		if(devices.length > 1){
			
			if(window.cameraFavoriteId){
				//do nothing, we've got an id
			}else{
				//window.cameraFavoriteId = '99eacaafc3e7b9206d7e987c0a2e98a32fb2046fc274f98fbda88fed43ed88a1';
				for (let i = 0; i < devices.length; i++) {
					let id = devices[i].id;
					let label = devices[i].label;
					
					if(!label.toLowerCase().includes("front")){
						
						cameraOptions += "<form class='setCamera' method='post' action='/onlinerequest/set-camera'><input type='hidden' name='camera_name' value='"+label+"' /><input type='hidden' name='camera_id' value='"+id+"' /><input class='btn green-button' type='submit' value='"+label+"' /></form>";
						
						console.log('Displaying Camera Options');
					}
				}
				if(typeof(window.cameraFavoriteId) == 'undefined'){
					cameraOptions += '<input id="remember_camera" name="remember_camera" type="checkbox" value="create_camera_cookie" /> Remember This Camera';
					document.getElementById('quickCameraOptions').innerHTML = cameraOptions;
					return '';
				}
											
			}
		}else{
			//set the id
			console.log('Single Camera Found');
			window.cameraFavoriteId = devices[0].id;
		}
	}else{
		document.getElementById('cameraOptions').innerHTML = 'No Cameras were found'; 
	}
}

export function cameraOptions(){
	// This method will trigger user permissions and display camera options for the user
	Html5Qrcode.getCameras().then(devices => {
		console.log('Loading Device Options');
		console.log(devices);
		
		let cameraOptions = '';
		if (devices && devices.length) {
			console.log('Loading Camera Options');
			
			for (let i = 0; i < devices.length; i++) {
				let id = devices[i].id;
				let label = devices[i].label;
				
				if(!label.toLowerCase().includes("front")){
				
					cameraOptions += "<form method='post' class='setCamera' action='/onlinerequest/set-camera'><input type='hidden' name='camera_name' value='"+label+"' /><input type='hidden' name='camera_id' value='"+id+"' /><input class='btn green-button' type='submit' value='"+label+"' /></form>";
				}
			}
			
			console.log('Displaying Camera Options');
			document.getElementById('cameraOptions').innerHTML = cameraOptions; 
			
			//var cameraId = devices[1].id;
			// .. use this to start scanning.
			//loadCamera(cameraId);
		}else{
			document.getElementById('cameraOptions').innerHTML = 'No Cameras were found'; 
		}
	}).catch(err => {
	  // handle err
	});
}