
// global
import { ADD, itemQuantityAdjustment } from './globals'

export default function updateEditQuantityCard () {
  const changevalue = $("input[name='changejs']").val()
  const oldValue = $("input[name='quantityjs']").val()
  
  if (itemQuantityAdjustment === ADD) {
    $("input[name='newamountjs']").val(Number(oldValue) + Number(changevalue))
  } else {
    $("input[name='newamountjs']").val(Number(oldValue) - Number(changevalue))
  }
}
