// TODO: make this return undefined for no cookie instead of '', make sure use cases reflect the change
// function getCookie(cname) {
//   const name = cname + '='
//   const decodedCookie = decodeURIComponent(document.cookie)
//   const ca = decodedCookie.split(';')
//   for (let i = 0; i < ca.length; i++) {
//     let c = ca[i]
//     while (c.charAt(0) === ' ') {
//       c = c.substring(1)
//     }
//     if (c.indexOf(name) === 0) {
//       return c.substring(name.length, c.length)
//     }
//   }
//   return ''
// }
export default function twoDigits (d) {
  if (d >= 0 && d < 10) { return '0' + d.toString() }
  if (d > -10 && d < 0) { return '-0' + (-1 * d).toString() }
  return d.toString()
}
