export default function handleEditPermissions() {
  // Handle Initial
  //   setPermissions($("user_type").val());

  let role_details 
  $(document).ready(async ()=> {
    /*let roles = await fetch('/users/get-local-roles')
    roles = await roles.json()
    console.log(roles)
    for (let i in roles) {
      let role = roles[i]

      $('#role_id').append(`<option value="${role.id}">${role.name}</option>`)
    }

    let res = await fetch('/users/get-roles')
    res = await res.json()
    role_details = res*/
    // console.log(role_details)
  })

  // Handle On Change
  $("#role_id").change(async (e) => {
    $('#permission_block').empty()
    let data = new FormData()
    data.append('role_id', e.target.value)

    console.log(e.target.value)
    // console.log(e.target.value)
    let roles = await fetch('/users/get-local-roles-permission', {
      method: 'POST',
      body: data
    })
    roles = await roles.json()
    // console.log(roles)

    for (let i in roles) {
      let id = roles[i].id
      // console.log(id)
      let details
      for (let x in role_details)  {
        let detail = role_details[x]
        if (detail.id == id) details = detail
      }
console.log(details)
      $('#permission_block').append(`<div>${details.name}</div>`)
    }
    
  });
}


