export default class BarcodeResultsManager {
  constructor () {
    this.results = []
  }

  push (code, format) {
    const matchingResult = this.results.filter(result => result.code === code && result.format === format)
    if (matchingResult.length === 0) {
      this.results.push({
        code: code,
        format: format,
        count: 1
      })
    } else {
      matchingResult[0].count++
    }
  }

  getResults () {
    return this.results
  }

  getPrimaryResult () {
    const highestCount = Math.max.apply(Math, this.results.map(function (o) {
      return o.count
    }))
    return this.results.filter(result => result.count === highestCount)[0].code
  }

  getPrimaryResultFormat () {
    const highestCount = Math.max.apply(Math, this.results.map(function (o) {
      return o.count
    }))
    console.log(this.results.filter(result => result.count === highestCount)[0].format)
    return this.results.filter(result => result.count === highestCount)[0].format
  }
}
