export default function confirmBox (details) {
	console.log(details);
	
	$('#confirmBox').show();
	window.hasBeenRun = false;
	
	//populate the box
	$('#confirmBox').attr('data-run', details.submit);
	$('#confirmBox').attr('data-id', details.id);
	
	$('#confirmBox h2').html(details.title);
	$('#confirmBox p').html(details.message);
	
	if(details.multi == 'true'){
		$('.extra-step').show();
	}else{
		$('.extra-step').hide();
	}
	
	if(details.items == 'true'){
		$('.extra-items').show();
	}else{
		$('.extra-items').hide();
	}
	
	//check for click
	$('#confirmButtons .btn').on('click', function(e){
		let runner = $('#confirmBox').data('run');
		
		if($(this).data('value') == true){
			$('#confirmValue').val($(this).data('value'));
			$(runner).submit();
		}else{
			$('#confirmValue').val('_wait');
		}
		
		$('#confirmBox').hide();
	});
}
