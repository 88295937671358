export default function setBarcodeInputsForEdit (barcode) {
  $("input[name='barcode']").val(barcode.barcode)
  $("input[name='barcode_id']").val(barcode.id)
  $("input[name='product_name']").val(barcode.product_name)
  $("input[name='product_description']").val(barcode.product_description)
  $("input[name='uom_primary_unit_qty']").val(barcode.uom_primary_unit_qty)
  $("input[name='uom_secondary_unit_qty']").val(barcode.uom_secondary_unit_qty)
  $("input[name='uom_primary_unit_type']").val(barcode.uom_primary_unit_type)
  $("select[name='uom_primary_unit_type']").val(barcode.uom_primary_unit_type)
  $("input[name='uom_secondary_unit_type']").val(barcode.uom_secondary_unit_type)
  if(barcode.uom_secondary_unit_type != ''){
	$("select[name='uom_secondary_unit_type']").val(barcode.uom_secondary_unit_type)
  }
}
