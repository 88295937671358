import getCookie from './getCookie'
import prepareItemsIndexPage from './prepareItemsIndexPage'
import prepareAddItemPage from './prepareAddItemPage'
import prepareInventoryReportPage from './prepareInventoryReportPage'
import prepareEditItemPage from './prepareEditItemPage'
import prepareArchiveReportPage from './prepareArchiveReportPage'
import manageRoomsPage from './manageRoomsPage'
import prepareAlertsIndexPage from './prepareAlertsIndexPage'
import itemsIndex from './pages/itemsIndex'

// globals

export default async function pageSpecificJS () {
  const windowLoc = $(location).attr('pathname') // jquery format to get window.location.pathname
  let lastKeyPressWasCaps = null
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  switch (windowLoc) {
    case '/login':
      if (urlParams.get('clientslug')) {
        $('input[name="clientslug"]').val(urlParams.get('clientslug'))
      }
      $('#password').keydown((e) => {
        const s = e.key
        if (e.keyCode === 20) {
          if (lastKeyPressWasCaps === true) {
            $('#caps-lock-notification').hide()
            lastKeyPressWasCaps = false
          } else if (lastKeyPressWasCaps === false) {
            $('#caps-lock-notification').show()
            lastKeyPressWasCaps = true
          }
          return
        }
        /*if (s.toUpperCase() === s && s.toLowerCase() !== s && !e.shiftKey) {
          $('#caps-lock-notification').show()
          lastKeyPressWasCaps = true
        } else {
          $('#caps-lock-notification').hide()
          lastKeyPressWasCaps = false
        }*/
      })
      break
    case '/edit-item':
      prepareEditItemPage(urlParams.get('id'))
      break

    case '/add-item':
      prepareAddItemPage()
      break

    case '/items': {
      // if we have a COOKIE, load it
      if (getCookie('persistent_item_filter') != '') {
        prepareItemsIndexPage()
        const persistent_item_filter = JSON.parse(getCookie('persistent_item_filter'))
        prepareItemsIndexPage(persistent_item_filter)

        $('#sortby-container input[type=radio]').each(function () {
          if ($(this).val() == persistent_item_filter.sort) {
            $(this).attr('checked', 'checked')
          }
        })

        $('#filter-container input[type=radio]').each(function () {
          if ($(this).val() == persistent_item_filter.filter) {
            $(this).attr('checked', 'checked')
          }
        })

        $('#direction-container input[type=radio]').each(function () {
          if ($(this).val() == persistent_item_filter.sortdirection) {
            $(this).attr('checked', 'checked')
          }
        })

        $('#filter-container #room-select option').each(function () {
          if ($(this).val() == persistent_item_filter.roomFilter) {
            $(this).attr('selected', 'selected')
          }
        })

        persistent_item_filter.sortSetting
      } else {
        prepareItemsIndexPage()
      }

      // This lazy loads the js for items index so pageload isn't impacted
      /*const {
        default: ItemsIndex
      } = await import('./pages/itemsIndex')
      new ItemsIndex(JSON.parse(getCookie('active_location'))).test()*/
      break
    }
    case '/alerts':
      prepareAlertsIndexPage()
      break
    case '/reports/inventory-report':
      prepareInventoryReportPage()
      break
    case '/manage/items':
      prepareArchiveReportPage()
      break
    case '/manage/rooms':
      manageRoomsPage()
      break
  }
}
