import setLongLastingCookie from './setLongLastingCookie'
import displayNotification from './displayNotification'
import syncDown from './syncDown'
import getCookie from './getCookie'

export default async function loginHandler () {
  let slugStored = false

  if (!getCookie('clientslug') || getCookie('clientslug') == 'undefined') {
    console.log('no slug')
  } else {
    $('#clientslug').remove()

    slugStored = true
  }

  $('#login form').on('submit', (e) => {
    e.preventDefault()
    const formElement = $('form')[0]
	console.log(formElement);
    const form_data = new FormData(formElement)
    // let debug = form_data

    // Skip Slug
    if (slugStored) {
      form_data.set('clientslug', getCookie('clientslug').replace('[', '').replace(']', '').replace('"', '').replace('"', ''))
      // console.log()
    }

    fetch('/api/login', {
      method: 'POST',
      body: form_data
    }).then((response) => {
      $('#main-loading-spinner').fadeIn()
      response.json().then(async (data) => {
        if (data.status === 'success') {
		  sessionStorage.setItem('itemBackButton', 'items'); //used for navigation
          setLongLastingCookie('userdetails', data.userdetails)
          setLongLastingCookie('usersettings', data.usersettings)
          setLongLastingCookie('clientdetails', data.clientdetails)
		  
		  if(getCookie('show_tooltips') == ''){
			setLongLastingCookie('show_tooltips', true);
		  }
		  
          setLongLastingCookie('clientslug', form_data.getAll('clientslug'))
          console.log('cookie set')
          await syncDown()
          window.location.href = '/home'
        } else {
          $('#main-loading-spinner').hide()
          displayNotification(data)
        }
      })
    })
  })

  $('#reset-slug').on('click', function () {
    console.log('reset cookie')
    setLongLastingCookie('clientslug', undefined)
    slugStored = false
    location.reload()
  })
  
  
	$('#password-reset').on('submit', async (e)=> {
		e.preventDefault();
		
		let error = true;
		let steptext = '';
		let submittext = '';
		let step = $('#step').val();
		let slug = $('#clientslug').val();
		let email = $('#email').val();
		let resetkey = $('#resetkey').val();
		let passwordnew = $('#password').val();
		let passwordverify = $('#passwordverify').val();
		let request_body = new FormData();
		console.log(slug);
		if(typeof slug == 'undefined'){
			slug = getCookie('clientslug').replace('[', '').replace(']', '').replace('"', '').replace('"', '');
		}
		
		console.log(slug);
		console.log('Step: '+step);
		if(step == 1){
			//we have slug and email, so let's send them a reset code
			request_body.append("slug", slug);
			request_body.append("email", email);
			
			let api_request = await fetch("/api/reset-email", {
				method: 'POST',
				body: request_body
			});
			
			let api_response = await api_request.json();
			console.log(api_response);

			error = api_response.error;
			
			if(!error){
				steptext = `<p>We have sent this email a reset code. This code will expire in 15 minutes. Once you have the code entered, please submit the form again.</p>`;
				submittext = 'Verify Code';
				$('#step').val(2);
				$('.r1').hide();
				$('.r2').show();
				
				window.slug = slug;
				window.email = email;
			}else{
				steptext = api_response.errorMessage
			}
		}
		
		if(step == 2){
			//we have slug, email and key, so we need to verify the code
			request_body.append("slug", window.slug);
			request_body.append("email", window.email);
			request_body.append("reset_key", $('#resetkey').val());
			
			let api_request = await fetch("/api/reset-verify", {
				method: 'POST',
				body: request_body
			});
			
			let api_response = await api_request.json();
			console.log(api_response);

			error = api_response.error;
			
			if(!error){
				$('.r3').show();
				$('.r2').hide();
				steptext = `<p>Please provide your new password.</p>`;
				submittext = 'Update Password';
				$('#step').val(3);
				
				window.resetkey = $('#resetkey').val();
			}else{
				steptext = api_response.errorMessage
			}
		}
		
		if(step == 3){
			//we have all our data, verify and reset the password
			request_body.append("slug", window.slug);
			request_body.append("email", window.email);
			request_body.append("reset_key", window.resetkey);
			request_body.append("password", $('#password').val());
			
			if($('#password').val() != $('#passwordverify').val()){
				steptext = `<p>The passwords did not match, please enter them again.</p>`;
				submittext = 'Update Password';
			}else{
			
				let api_request = await fetch("/api/reset-update", {
					method: 'POST',
					body: request_body
				});
				
				let api_response = await api_request.json();
				console.log(api_response);

				error = api_response.error;
				
				if(!error){
					steptext = `<p>Your password has been updated. You can now <a href="/login">login</a> using it.</p>`;
					submittext = 'Password Updated';
					$('.r3').hide();
					$('#reset-submit').remove();
					$('#step').val(0);
				}else{
					steptext = api_response.errorMessage
				}
			}
		}
		
		//let the user know what's going on
		$('#reset-information-box').html(steptext);
		
		if(!error){
			$('#reset-submit').val(submittext);
		}
	});
}
