import SktDb from './sktDb';
import setBarcodeInputsForEdit from './setBarcodeInputsForEdit';
import setUpEditQuantity from './setUpEditQuantity';
import getLocationsAndRoomsForHTML from './getLocationsAndRoomsForHTML';
import transferSubmit from './transferSubmit';
import displayNotification from './displayNotification';
import renderTransferData from './renderTransferData';

export default async function prepareEditItemPage(id) {
	//create some variables
	const Location = ({ id, name, live }) => {
		if(live == 1){
			return `<option value="${id}">${name}</option>`;
		}
	}
	const Room = ({ id, name}) => {
		
		return `<option value="${id}">${name}</option>`;
		
	}
	
	//trigger the transfer render when the room changes
	$('body').on('change', '#room-list', async (e) => {
		renderTransferData();
	});
	
	//trigger the transfer render when the transfer value changes
	$('body').on('keyup', '#transfer_quantity', async (e) => {
		renderTransferData();
	});
	
	$('.show-on-edit').hide();
	
	//create the 'back' button url
	let backButton = '/'+sessionStorage.getItem('itemBackButton');
	$('#back-button').attr('href', backButton);

	//get the current records to display.
	const room_inventory = await SktDb.getRecord('room-inventory', id);
	const barcode = await SktDb.getRecord('barcode', room_inventory.barcode_id);
	const room_barcode_alert = await SktDb.getRecord(
		'room-barcode-alert',
		room_inventory.room_barcode_alert_id
	);
	const room = await SktDb.getRecord('room', room_inventory.room_id);
	const location = await SktDb.getRecord('location', room.location_id);

	//Set our values on the page.
	setBarcodeInputsForEdit(barcode);
	$("input[name='room_inventory_id']").val(room_inventory.id);
	$("input[name='room_barcode_alert_id']").val(room_barcode_alert.id);
	$("input[name='current_room_inventory_id']").val(room_inventory.id);
	$("input[name='quantity']").val(room_inventory.quantity);
	$("input[name='transfer_quantity']").attr({
	max: room_inventory.quantity,
	});
	$("input[name='original_quantity']").val(room_inventory.quantity);
	$("input[name='start_room']").val(room.name);
	setUpEditQuantity();
	$("input[name='min_threshold']").val(room_barcode_alert.min_threshold);
	$("input[name='max_threshold']").val(room_barcode_alert.max_threshold);
	
	if(room_inventory.quantity != 0){
		$('#delete-item-button').hide();
		$('#delete-notice').show();
	}
  
	if (barcode.image_url && navigator.onLine) {
		$('.item-image')
			.css('background-image', 'url("' + barcode.image_url + '")')
			.css('background-size', 'cover');
	}
	$("textarea[name='details']").val(room_inventory.details);

	$('#transfer-card #room-list').html(await getLocationsAndRoomsForHTML());
	$("#transfer-card #room-list option[value='" + room.id + "'][data-type='room']").remove();

	const rooms = await SktDb.readAll('room');
	const locations = await SktDb.readAll('location');
	$('.input-wrapper #locations').append(locations.map(Location).join(''));
	$('.input-wrapper #locations').val(Number(location.id));

	//prepare and list our locations
	const setUpItemLocationSelect = (e) => {
		const locationId = $('.input-wrapper #locations').val();
		const locationsRooms = rooms.filter(
			(room) => room.location_id === locationId
		);
		$('.input-wrapper #rooms').html('');
		$('.input-wrapper #rooms').append(locationsRooms.map(Room).join(''));
	};
	setUpItemLocationSelect();
	$('.input-wrapper #rooms').val(room.id);
	$('.input-wrapper #locations').on('change', setUpItemLocationSelect);


	//HANDLE A DELETE ITEM REQUEST
	$('#delete-item-button').on('click', async () => {
		if (confirm('Are you sure you want to remove this item from this room?')) {
			const response = await fetch(
			'/api/delete-room-inventory/' + room_inventory.id,
				{
				  method: 'DELETE',
				}
			);
			
			const responseData = await response.json();
			
			if (responseData.message) {
			
				const notification = {
				  status: responseData.status,
				  message: responseData.message,
				};
				
				if (responseData.status === 'success') {
					sessionStorage.setItem('notification', JSON.stringify(notification));
				} else if (responseData.status === 'failed') {
					displayNotification(notification);
					$('#main-loading-spinner').hide();
					return;
				}
			}
			
			await SktDb.remove('room-inventory', room_inventory.id);
			document.location = '/items';
		}
	});

	$('#edit-base-item').on('click', () => {
	$('.hide-for-base-item-edit, #edit-base-item-caution').slideToggle();
	$('.show-on-edit').show();
	$('.hide-on-edit').remove();

	const action = $('#edit-item-container form').attr('action');
	const newAction =
	action === '/edit-item' ? '/edit-base-item' : '/edit-item';
	const text = $('#edit-base-item').text();
	$('#edit-item-container form').attr('action', newAction);
	$('.item-top-card input, #uom-card input').not('#barcode-input-form-field').toggleClass('locked-input');
	$('.item-top-card input, #uom-card input').not('#barcode-input-form-field').attr('readonly', (_, attr) => {
	return !attr;
	});
	$('#edit-base-item').text(
	text === 'Edit Base Item' ? 'Edit Room Inventory' : 'Edit Base Item'
	);
	setBarcodeInputsForEdit(barcode);
	});

	$('#transfer-card').on('submit', async (e) => {
	await transferSubmit(e, '/items');
	});



	// Standard Form Submission
	$('#edit-item-container').on(
		'submit',
		"form[action='/edit-item']",
	async (e) => {

		e.preventDefault();
		let savedImageData = '';
		
		//check some required fields
		let uomField = $('#uom_primary_unit_type').val();
		if(uomField == 'N/A' || uomField == ''){
			const uomError = {
				status: 'failed',
				message: 'Unit of Measure is Required'
			}
			displayNotification(uomError);
			return false;
		}
		
		//show the spinner
		$('#main-loading-spinner').fadeIn();
		
		//get the correct redirection
		let redirectTo = $('#edit-item-form').data('savepath');
		
		//get our form data set
		const formElement = $('#edit-item-form')[0];
		const formData = new FormData(formElement);

		const isNotSynced = formData.get('room_inventory_id').toString(10).length > 9;
		
		//if our ID is messed up, then it isn't in the database yet and we need to update our local data
		if (isNotSynced) {
			const oldFailedPost = await SktDb.getRecord(
				'failedposts',
				formData.get('room_inventory_id')
			);
			
			const object = {};
				formData.forEach((value, key) => {
				object[key] = value;
			});
			
			const json = JSON.stringify(object);
			
			oldFailedPost.formdata = json;
			await SktDb.add(oldFailedPost, 'failedposts');
			
		//if it is in the database, then send out the API call
		} else {
			const response = await fetch('/api/edit-item', {
				method: 'POST',
				body: formData,
			});
			
			const responseData = await response.json();
			console.log(responseData);
			
			if (responseData.message) {
				
				savedImageData = responseData.saved_image_url

				const notification = {
					status: responseData.status,
					message: responseData.message,
				};
			
				if (responseData.status === 'success') {
					sessionStorage.setItem(
						'notification',
						JSON.stringify(notification)
					);
				} else if (responseData.status === 'failed') {
					displayNotification(notification);
					$('#main-loading-spinner').hide();
					return;
				}
			}
		}
		
		
		//Update INDEXED DB
		//We manually update these so they load correct immediately, eventually the cache will update and resolve them
		const room_inventory_id = formData.get('room_inventory_id');
		const barcode_id = formData.get('barcode_id');
		const room_barcode_alert_id = formData.get('room_barcode_alert_id');
		
		
		const barcode = {
			id: barcode_id,
			barcode: formData.get('barcode'),
			live: '1',
			product_name: formData.get('product_name'),
			product_description: formData.get('product_description'),
			uom_primary_unit_qty: formData.get('uom_primary_unit_qty'),
			uom_secondary_unit_qty: formData.get('uom_secondary_unit_qty'),
			uom_primary_unit_type: formData.get('uom_primary_unit_type'),
			uom_secondary_unit_type: formData.get('uom_secondary_unit_type'),
			image_url: formData.get('image_reset') === 'reset' ? null : savedImageData
		};
			
		const room_barcode_alert = {
			id: room_barcode_alert_id,
			room_id: formData.get('room_id'),
			barcode_id: barcode_id,
			min_threshold: formData.get('min_threshold'),
			max_threshold:
				formData.get('max_threshold') === '0'
				? 0
				: formData.get('max_threshold'),
		};

		const room_inventory = {  
			id: room_inventory_id,
			room_id: formData.get('room_id'),
			barcode_id: barcode_id,
			room_barcode_alert_id: room_barcode_alert_id,
			quantity: formData.get('quantity'),
			details: formData.get('details'),
			update_time: new Date().toMysqlFormat(),
			deleted: '0',
		};
		
		console.log('New Barcode Details');
		console.log(barcode);
		console.log('Image Removal Data');
		console.log(formData.get('image_reset'));
		console.log('Database Image Response');
		console.log(savedImageData);
		
		await SktDb.add(barcode, 'barcode');
		await SktDb.add(room_barcode_alert, 'room-barcode-alert');
		await SktDb.add(room_inventory, 'room-inventory');
		
		document.location = redirectTo;
	});

  $('#edit-item-container').on(
    'submit',
    "form[action='/edit-base-item']",
    async (e) => {
		
		alert('submitted!');
		return false;
		
      e.preventDefault();
	  let redirectTo = $('#edit-item-form').data('savepath');
      $('#main-loading-spinner').fadeIn();

      const formElement = $('#edit-item-form')[0];
      const formData = new FormData(formElement);
      const isNotSynced = formData.get('barcode_id').toString(10).length > 9;

      const barcode = {
        id: formData.get('barcode_id'),
        barcode: formData.get('barcode'),
        product_name: formData.get('product_name'),
        product_description: formData.get('product_description'),
        uom_primary_unit_qty: formData.get('uom_primary_unit_qty'),
        uom_secondary_unit_qty: formData.get('uom_secondary_unit_qty'),
        uom_primary_unit_type: formData.get('uom_primary_unit_type'),
        uom_secondary_unit_type: formData.get('uom_secondary_unit_type'),
		image_url:
		formData.get('image_reset') === 'reset'
			? null
			: formData.get('image_src')
      };

      if (isNotSynced) {
        const oldFailedPost = await SktDb.getRecord(
          'failedposts',
          formData.get('barcode_id')
        );
        const object = {};
        formData.forEach((value, key) => {
          object[key] = value;
        });
        const json = JSON.stringify(object);
        oldFailedPost.formdata = json;
        await SktDb.add(oldFailedPost, 'failedposts');
      } else {
        const response = await fetch('/api/edit-base-item', {
          method: 'POST',
          body: formData,
        });
        const responseData = await response.json();
        console.log(responseData);
        if (responseData.message) {
          const notification = {
            status: responseData.status,
            message: responseData.message,
          };
          if (responseData.status === 'success') {
            sessionStorage.setItem(
              'notification',
              JSON.stringify(notification)
            );
          } else if (responseData.status === 'failed') {
            displayNotification(notification);
            $('#main-loading-spinner').hide();
            return;
          }
        }
      }
      await SktDb.add(barcode, 'barcode');
      //document.location = '/edit-item?id='+formData.get('room_inventory_id');
      document.location = redirectTo;
    }
  );
  
	$(".charlimit").each(function(){
		let charValueKey = $(this).val().length;
		let charMaxKey = $(this).data('limit');
		$(this).siblings('.charlimitcount').html(`${charValueKey}/${charMaxKey}`);
	});
	
	$('#reset-image-default').on('click', function(e){
		$('#item-image-reset').val('reset');
		let url = `url('/resources/img/add_a_photo-24px.svg') no-repeat center`;
		$('.item-image').css('background', url);
		$('.item-image').css('background-size', '50%');
		$('.item-image').css('background-color', '#F7F7F7');
	});
}
