import SktDb from './sktDb'
import getCookie from './getCookie'

export default async function getLocationsAndRoomsForHTML (selectableLocations = false) {
  const disabledOption = selectableLocations ? '' : 'disabled'
  let result = ''
  let locations = await SktDb.readAll('location')
  let rooms = await SktDb.readAll('room')
  // console.log('here')
  // console.log(rooms)
  rooms = rooms.filter(x => x.deleted == 0)
  locations = locations.filter(y => y.live == 1)

  for (let i = 0; i < locations.length; i++) {
    result += `<option style="background:#DDD;"${disabledOption} value='${locations[i].id}' data-type="location">${locations[i].name}</option>`
    for (let i2 = 0; i2 < rooms.length; i2++) {
      if (locations[i].id === rooms[i2].location_id) {
        result += `<option value='${rooms[i2].id}' data-type="room">&nbsp;&nbsp;&nbsp;${rooms[i2].name}</option>`
      }
    }
  }
  return result
}
