export default function displayNotification (notification) {
  $('.notification-flash').hide()
  if ($('#items-sub-menu').length) {
    $('#items-sub-menu').after("<div class='notification-flash item-page-notification notification-" + notification.status + "' ><p>" + notification.message + '<span>x</span></p></div>')
  } else {
    $('body').append("<div class='notification-flash notification-" + notification.status + "' ><p>" + notification.message + '<span>x</span></p></div>')
  }
  setTimeout(() => {
    $('.notification-success').fadeOut('fast')
  }, 15000)
}
