import displayNotification from "./displayNotification";

export default (function () {
	const parentDiv = $('#barcode-scan-test')

	// Setup for populating add-item page
	const ItemNameField = $('#product-name')
	const ItemDescriptionField = $('#product-description')
	const UPCLookupButton = $('.upc-lookup-button')
	const UPCLookupField = $('#barcode-input-form-field')

	const url = 'https://barcode.monster/api/'
	const testBarcode = '9780140157376'

	$('#smartLookUp').on('click', '.upc-lookup-button', function (e) {
		e.preventDefault();

		let activeData = UPCLookupField.val()

		// Parse activate data for barcode

		// const validateActiveData = activeData.substr(14)
		console.log(activeData)

		if (!isValidUpcOrEan(activeData)) {
		  return false
		} else {
			ItemNameField.val('')
			ItemNameField.val('Looking up barcode...')
			
			activeData = `barcode-input=${activeData}`
			apiLookup(activeData)
		}

	})
})()


	export function isValidUpcOrEan (sku) {
		
		function calculateCheckSum(previousValue, currentValue, index) {
			//	Calculation of a 12 or 13 digit GTIN checksum digit.
			//	Reference here:  https://www.gs1.org/services/how-calculate-check-digit-manually
			//	We have removed the official check digit already, and reversed the entire SKU elements such that the N11 or N12 element
			//	will always be multiplied by 3 (again, see the table and process at link above).
			//	This is effectively a sum-of-products with the input array used, with every even indexed element
			//	being multiplied by three before being added to the running sum, while odd indexed elements as just added in themselved.
			const checkSum = index % 2 === 0 ? previousValue + currentValue*3 : previousValue + currentValue;
			//	The console log here allows us to debug the process of calculating the checksum. Keep commented out during production use.
			console.log(`previousValue: ${previousValue}, currentValue: ${currentValue}, index: ${index}, checkSum: ${checkSum}`);
			return checkSum;
		}
		
		//	If we do not have a valid UPC-A (12) or EAN (13) length SKU, do not proceed.
		if (sku.length < 12 || sku.length > 13) { return false }
		
		//	We need the checkDigit to complete the whole process. This is the last digit at the end of the GTIN/SKU.
		const checkDigit = Number(sku.substring(sku.length - 1))
		
		//	Reverse the digits and store as strings in an array. We also ignore and do not include the checkDigit
		const arr = sku.substring(0, sku.length - 1).split('').reverse()
		
		//	Re-map the strings in the array to numbers
		//	Non-numeric strings will become NaN's.
		var new_arr = arr.map((num)=>{
			return Number(num)
		});
		
		//	Call the checkSum calculation function. To understand the function of the array.reduce() method, see the following reference:
		//	https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/Reduce
		//	We add the zero here so that we have a "previous value" for the "reduce" method to work with. This allows the first element
		//	of the array to always be multiplied by three.
		let checkSum = new_arr.reduce(calculateCheckSum,0);
		
		//	To see if we have a valid 12 or 13 digit GTIN, we merely add the checkDigit to the derived checkSum. If this new
		//	total MOD 10 equals zero, we have a valid SKU (either UPC-A or EAN).
		return (checkSum + checkDigit) % 10 === 0
		
	}
	
export function isValidBarcode (barcode) {
  // check length
  console.log(barcode.length);
  if (barcode.length != 12) { return false }
  /*
  if (barcode.length < 8 || barcode.length > 18 ||
      (barcode.length != 8 && barcode.length != 12 &&
      barcode.length != 13 && barcode.length != 14 &&
      barcode.length != 18)) {
    return false
  }
  */

  const lastDigit = Number(barcode.substring(barcode.length - 1))
  let checkSum = 0
  if (isNaN(lastDigit)) { return false } // not a valid upc/ean

  const arr = barcode.substring(0, barcode.length - 1).split('').reverse()
  let oddTotal = 0; let evenTotal = 0

  console.log(arr);
  
  for (let i = 0; i < arr.length; i++) {
    if (isNaN(arr[i])) { return false } // can't be a valid upc/ean we're checking for

    if (i % 2 == 0) { oddTotal += Number(arr[i]) * 3 } else { evenTotal += Number(arr[i]) }
  }
  checkSum = (10 - ((evenTotal + oddTotal) % 10)) % 10

  console.log((evenTotal + oddTotal) % 10);
  console.log(checkSum);
  
  // true if they are equal
  return checkSum == lastDigit
}

// When search redirects to add-item, populate with upc data
$(document).ready(() => {
  if (window.localStorage.getItem('upc-add') == 'true') {
    window.localStorage.setItem('upc-add', 'false')
    let data = JSON.parse(window.localStorage.getItem('upc'))
    console.log(data)

    const ItemNameField = $('#product-name')
    const ItemDescriptionField = $('#product-description')
    const ItemSKUField = $('#barcode-input-form-field')
    const ItemImageField = $('.item-image')
    const ImageFile = $('#item-image-input')
    const ImageUrlText = $('#text-image-url-save')

    ItemNameField.val(data.title)
	
	//write the barcode out depending on the type
	if(typeof(data.upc) != 'undefined'){
		ItemSKUField.val(data.upc)
	}else{
		ItemSKUField.val(data.ean)
	}
	
    try {
      
      ItemDescriptionField.val(data.description)

      if (data.description == '') {
        ItemDescriptionField.val('N/A')
      }
    } catch (error) {
      ItemDescriptionField.val('N/A')
      
    }
    try { 
      if (data.images.length == 0) {
        console.log('no images')
      } else {
        let url = `url(${data.images[0]}) no-repeat center`;
        ItemImageField.css('background', url);
        ImageFile.type = "text"
        ImageFile.val = data.images[0]
        ImageUrlText.val(data.images[0]).trigger('change')
      }
    } catch (error) {
      console.log('no image')
      // console.log(data.images[0])
    }
  }
})

export async function apiLookup (activeData) {
	$('#main-loading-spinner').show();
	
  const request = $.post(
    '/api/barcode-scan-api',
    activeData,
    callBackFunction,
    'json'
  ).then(res => {
    return res
  }).done(console.log('done')).fail(function(xhr, status, error) {
    console.log('error time')
    console.log(error)
    console.log(status)
    console.log(xhr)
	
	const notification = {
	  status: 'failed',
	  message: 'We could not find any information for this barcode. Please enter the data manually.',
	};
	
	displayNotification(notification);
	$('#main-loading-spinner').hide();
	
    // error handling
}); // end post function
  async function callBackFunction (data, status, this_obj) {
    console.log('request made')
	$('#main-loading-spinner').hide();

    /* write server response to console for troubleshooting */
     //console.log(data)
     //console.log(status)

    if (status == 'success') {
      // if the ajax responded, display the output to the users -- this is a built in response from AJAX

      // the original data sent by the form for debugging purposes
      if (data.form_status == 'success') { // I like to send back a response based on internal validation, that's this.
        /*
        IF VALIDATION IS GOOD, THEN DO THIS CODE

        */
        if (data.added_to_global) { console.log('added new SKU to global table') }
        
        const items = data.content.items[0]
        let json = JSON.stringify(items)
        window.localStorage.setItem('upc', json)
        window.localStorage.setItem('upc-add', 'true')

        window.location.replace("/add-item");
        // const ItemNameField = $('#product-name')
        // const ItemDescriptionField = $('#product-description')

        // ItemNameField.val('bababooie')

        
      } else {
		$('#product-name').val('')
		
		const notification = {
          status: 'failed',
          message: 'We could not find any information for this barcode. Please enter the data manually.',
        };
		displayNotification(notification);
		
        return {
          error: status,
          data: data
        }
        
      }
    }
  }
}