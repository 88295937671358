// globals
import { LOW_ALERT_QUANTITY_MOD, OVERSTOCK_QUANTITY_MOD } from './globals'

export default function getNotificationColor (quantity, lower_limit, upper_limit) {
	
	let midpoint = Number(((Number(upper_limit)-Number(lower_limit))/2))+Number(lower_limit);
	upper_limit = upper_limit || Infinity
	
	//if we don't have a min & max, don't return a bell
	if (Number(lower_limit) == 0 && Number(upper_limit) == 0) {
		return 'none'
	}

	//Red Bell filled with exclamation and “–“ sign – When inventory is at or below the minimum quantity. This should appear anytime the quantity is less than the lower limit
	else if (Number(quantity) < Number(lower_limit)) {
		return 'red'

	//Yellow Bell - When inventory is less than the midpoint between the minimum and maximum quantities AND greater than the minimum quantity.
	} else if (Number(quantity) < midpoint) {
		return 'yellow'
	
	//Green Bell – When inventory is less than or equal to the max quantity AND greater than or equal to the midpoint between the minimum and maximum quantities.
	} else if(Number(quantity) <= upper_limit && Number(quantity) >= midpoint) {
		return 'green'
		
	//Green Bell filled with exclamation and “+” sign – When inventory is above the maximum quantity.
	} else if(Number(quantity) > upper_limit) {
		return 'green-plus'
	}
  
}
